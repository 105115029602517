import React, { useEffect, useState } from "react";
import styles from "./CustomButton.module.css";

const CustomButton = ({ children, onClick, className }) => {
  const [maxDimensions, setMaxDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const buttons = document.querySelectorAll(`.${styles.customButton}`);
    let maxWidth = 0;
    let maxHeight = 0;

    buttons.forEach((button) => {
      const { offsetWidth, offsetHeight } = button;
      maxWidth = Math.max(maxWidth, offsetWidth);
      maxHeight = Math.max(maxHeight, offsetHeight);
    });

    setMaxDimensions({ width: maxWidth, height: maxHeight });
  }, [children]);

  return (
    <button
      className={`${styles.customButton} ${className}`}
      style={{
        minWidth: `${maxDimensions.width}px`,
        minHeight: `${maxDimensions.height}px`,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default CustomButton;
