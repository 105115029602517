export const formatDate = (createdAt) => {
  const date = new Date(createdAt);

  // Formatiranje dela datuma
  const day = date.getDate(); // Dan u mesecu
  const month = date.getMonth(); // Mesec (0-11)
  const year = date.getFullYear(); // Godina
  const hours = date.getHours(); // Sat (24-časovni format)
  const minutes = date.getMinutes(); // Minut
  const seconds = date.getSeconds(); // Sekunda

  // Definisanje meseca
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Formatiranje vremena u 24-časovnom formatu
  const formattedTime = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;

  // Formatiranje datuma u željeni redosled: Dan, Mesec, Godina
  const formattedDate = `${day < 10 ? "0" + day : day} ${months[month]} ${year}`;

  // Vraćanje kombinovanog rezultata
  return `${formattedDate} ${formattedTime}`;
};



  
  export const formatBirth = (dateString) => {
    const date = new Date(dateString);
  
    // Provera da li je datum validan
    if (isNaN(date)) return ""; // Ako nije validan datum, vrati praznu string
  
    // Dobijanje dana, meseca i godine
    const day = String(date.getDate()).padStart(2, "0"); // Dva cifre za dan
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Dva cifre za mesec
    const year = date.getFullYear();
  
    // Vraćanje u formatu dan-mesec-godina
    return `${day}/${month}/${year}`;
  };
  
  