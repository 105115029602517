import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/cofButton/Button';
import { createBatchScheduledTasks } from '../../actions/staff/scheduledActions';
import { fetchTasksList } from '../../actions/staff/tasksListActions';
import { RESET_CREATE_BATCH_SCHEDULED_TASKS_STATE } from '../../constants/staff/scheduledListConstants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomInput from '../../components/customInput/CustomInput';

const CreateScheduledTasksScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const { loading, error: createBatchError, success } = useSelector((state) => state.createBatchScheduledTasks);

  const clubId = userInfo.club;
  const { tasks } = useSelector((state) => state.tasks);

  const [taskId, setTaskId] = useState('');
  const [taskInputs, setTaskInputs] = useState([{ id: Date.now(), description: '', startTime: '' }]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    dispatch(fetchTasksList(clubId));
  }, [dispatch, clubId]);

  useEffect(() => {
    if (success) {
      navigate('/tasks/scheduled');
      dispatch({ type: RESET_CREATE_BATCH_SCHEDULED_TASKS_STATE });
    }
  }, [success, navigate]);

  const handleAddAnotherInputGroup = () => {
    setTaskInputs((prevInputs) => [
      ...prevInputs,
      { id: Date.now(), description: '', startTime: '' },
    ]);
  };

  const handleRemoveTask = (id) => {
    if (taskInputs.length === 1) {
      return;
    }
    setTaskInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const handleTaskCreate = (e) => {
    e.preventDefault();
    if (!taskId) {
      setErrorMessage(t('Please select a task.'));
      return;
    }

    if (taskInputs.some((input) => !input.description || !input.startTime)) {
      setErrorMessage(t('All fields are required.'));
      return;
    }

    setErrorMessage(''); // Reset greške
    const batchData = {
      taskId: taskId,
      scheduledTasksData: taskInputs.map((input) => ({
        startTime: input.startTime,
        description: input.description,
      })),
    };

    dispatch(createBatchScheduledTasks(userInfo.club, batchData));
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ paddingTop: '100px' }}>
      <Row className='mx-2'>
        <Col xs={12} className="d-flex align-items-center justify-content-center">
          <h2>{t('CreateScheduledTasks').toUpperCase()}</h2>
        </Col>

        <Col xs={12}>
          <Form>
            <Form.Group controlId="Vrsta zadatka" className="my-2">
              <Form.Control
                as="select"
                placeholder="Izaberite vrstu zadatka"
                onChange={(e) => setTaskId(e.target.value)}
                value={taskId}
                style={{
                  backgroundColor: '#415f74',
                  borderRadius: '0%',
                  color: 'white',
                  opacity: '0.6',
                  border: 'none',
                  padding: '0.5rem 0rem',
                  margin: '0.1rem 0rem',
                  textAlign: 'center',
                }}
              >
                <option>{t('SelectTask')}</option>
                {tasks.map((task) => (
                  <option key={task._id} value={task._id}>
                    {task.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
           

            {taskInputs.map((input, index) => (
              <div key={input.id}>
                <Form.Group style={{ marginBottom: '10px' }}>
                  <CustomInput
                    type="text"
                    placeholder={t('Task Description')}
                    value={input.description}
                    onChange={(e) => {
                      const newInputs = [...taskInputs];
                      newInputs[index].description = e.target.value;
                      setTaskInputs(newInputs);
                    }}
                  />
                </Form.Group>
                <Form.Group style={{ marginBottom: '10px' }}>
                  <CustomInput
                    type="datetime-local"
                    placeholder={t('StartTime')}
                    value={input.startTime}
                    onChange={(e) => {
                      const newInputs = [...taskInputs];
                      newInputs[index].startTime = e.target.value;
                      setTaskInputs(newInputs);
                    }}
                  />
                </Form.Group>
                <div className="mb-3">
                  <Button
                    className="w-100 rounded-0 border-0"
                    transparent={false}
                    onClick={() => handleRemoveTask(input.id)}
                  >
                    {t('Remove Task')}
                  </Button>
                </div>
                <div className="mb-3">
                  {index === taskInputs.length - 1 && (
                    <Button
                      className="w-100 rounded-0 border-0"
                      transparent={false}
                      onClick={handleAddAnotherInputGroup}
                    >
                      {t('+ Add Another Task')}
                    </Button>
                  )}
                </div>
              </div>
            ))}
            <div className="mb-3">
              {taskInputs.length > 0 && (
                <Button className="w-100 rounded-0 border-0" transparent={false} onClick={handleTaskCreate}>
                  {t('CreateScheduledTasks')}
                </Button>
                
              )}
               {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateScheduledTasksScreen;
