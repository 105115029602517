// documentReducer.js

import {
    UPLOAD_DOCUMENT_REQUEST,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAIL,

    GET_DOCUMENT_REQUEST,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAIL,

    UPLOAD_STAFFDOCUMENT_REQUEST,
  UPLOAD_STAFFDOCUMENT_SUCCESS,
  UPLOAD_STAFFDOCUMENT_FAIL,

  UPLOAD_PLAYERIMG_REQUEST,
  UPLOAD_PLAYERIMG_SUCCESS,
  UPLOAD_PLAYERIMG_FAIL,

  UPLOAD_USER_PASSPORT_IMG_REQUEST,
  UPLOAD_USER_PASSPORT_IMG_SUCCESS,
  UPLOAD_USER_PASSPORT_IMG_FAIL,
  } from '../../src/constants/uploadConstants';
  
  const initialState = {
    loading: false,
    success: false,
    error: null,
  };
  
  export const documentUploadReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPLOAD_DOCUMENT_REQUEST:
        return {
          ...state,
          loading: true,
          success: false,
          error: null,
        };
      case UPLOAD_DOCUMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          error: null,
        };
      case UPLOAD_DOCUMENT_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  

  const getinitialState = {
    loading: false,
    documents: [],
    error: null,
  };
  
  export const documentListReducer = (state = getinitialState, action) => {
    switch (action.type) {
      case GET_DOCUMENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case GET_DOCUMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          documents: action.payload,
          error: null,
        };
      case GET_DOCUMENT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  

  export const uploadStaffDocumentReducer = (state = {}, action) => {
    switch (action.type) {
      case UPLOAD_STAFFDOCUMENT_REQUEST:
        return { loading: true };
      case UPLOAD_STAFFDOCUMENT_SUCCESS:
        return { loading: false, success: true, data: action.payload };
      case UPLOAD_STAFFDOCUMENT_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const uploadPlayerImgReducer = (state = {}, action) => {
    switch (action.type) {
      case UPLOAD_PLAYERIMG_REQUEST:
        return { loading: true };
      case UPLOAD_PLAYERIMG_SUCCESS:
        return { loading: false, success: true, data: action.payload };
      case UPLOAD_PLAYERIMG_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  
  
  


  export const uploadUserPassportImgReducer = (state = {}, action) => {
    switch (action.type) {
      case UPLOAD_USER_PASSPORT_IMG_REQUEST:
        return { loading: true };
      case UPLOAD_USER_PASSPORT_IMG_SUCCESS:
        return { loading: false, success: true, data: action.payload };
      case UPLOAD_USER_PASSPORT_IMG_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  
  