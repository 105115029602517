import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadDocument } from '../actions/uploadAction';

const FileUploadComponent = ({ scheduledTaskId }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
  };

  const handleFileUpload = () => {
    if (!file) {
      setError('Please choose a file.');
      return;
    }
  
    setLoading(true);
  
    dispatch(
      uploadDocument(
        file,
        scheduledTaskId,
        (progress) => {
          setProgress(progress); // Ažuriranje progress bara
        }
      )
    )
      .then(() => {
        setLoading(false);
        setProgress(0); // Reset progress bara
      })
      .catch((err) => {
        setError('File upload failed. Please try again.');
        setLoading(false);
        setProgress(0);
      });
  };
  

  return (
    <div>
      <h1>File Upload</h1>

      {/* Zeleno dugme za biranje fajla */}
      <label
        htmlFor="file-input"
        style={{
          backgroundColor: 'rgb(0, 197, 35)', // Zeleni background
          color: '#fff', // Bela boja teksta
          padding: '10px 20px',
          fontWeight: 'bold',
          cursor: 'pointer', // Ruka kada se pređe preko dugmeta
          borderRadius: '5px', // Zaobljeni uglovi
          display: 'inline-block',
          marginBottom: '10px', // Razmak ispod dugmeta
          marginRight: '20px',
        }}
      >
        Choose File
        {/* Skriveni input za fajl */}
        <input
          type="file"
          id="file-input"
          onChange={handleFileChange}
          style={{ display: 'none' }} // Sakrivanje inputa
        />
      </label>

      {/* Prikaz imena izabranog fajla */}
      {file && <p style={{ color: 'green' }}>Selected File: {file.name}</p>}

      {/* Prikaz greške ako fajl nije izabran */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Progress bar */}
      {loading && (
        <div style={{ margin: '10px 0', width: '100%' }}>
          <div
            style={{
              backgroundColor: '#e0e0e0',
              borderRadius: '5px',
              height: '10px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                backgroundColor: 'rgb(0, 197, 35)',
                width: `${progress}%`,
                height: '100%',
                transition: 'width 0.2s ease-in-out',
              }}
            />
          </div>
          <p style={{ fontSize: '12px', marginTop: '5px' }}>{progress}%</p>
        </div>
      )}

      {/* Dugme za upload */}
      <button
        onClick={handleFileUpload}
        disabled={loading}
        style={{
          backgroundColor: 'rgb(0, 197, 35)',
          border: 'none',
          fontWeight: 'bold',
          padding: '10px 20px',
          color: '#fff',
          cursor: 'pointer',
          borderRadius: '5px',
        }}
      >
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  );
};

export default FileUploadComponent;
