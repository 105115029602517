export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'UPLOAD_DOCUMENT_FAIL';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAIL = 'GET_DOCUMENT_FAIL';

export const UPLOAD_STAFFDOCUMENT_REQUEST = 'UPLOAD_STAFFDOCUMENT_REQUEST';
export const UPLOAD_STAFFDOCUMENT_SUCCESS = 'UPLOAD_STAFFDOCUMENT_SUCCESS';
export const UPLOAD_STAFFDOCUMENT_FAIL = 'UPLOAD_STAFFDOCUMENT_FAIL';

export const UPLOAD_PLAYERIMG_REQUEST = 'UPLOAD_PLAYERIMG_REQUEST';
export const UPLOAD_PLAYERIMG_SUCCESS = 'UPLOAD_PLAYERIMG_SUCCESS';
export const UPLOAD_PLAYERIMG_FAIL = 'UPLOAD_PLAYERIMG_FAIL';

export const UPLOAD_USER_PASSPORT_IMG_REQUEST = 'UPLOAD_USER_PASSPORT_IMG_REQUEST';
export const UPLOAD_USER_PASSPORT_IMG_SUCCESS = 'UPLOAD_USER_PASSPORT_IMG_SUCCESS';
export const UPLOAD_USER_PASSPORT_IMG_FAIL = 'UPLOAD_USER_PASSPORT_IMG_FAIL';