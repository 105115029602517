const getSetting = (key) => {
    const value = process.env[key];

    if (!value) {
        throw new Error(`settings, there is no value for key: ${key}`);
    }

    return value;
}

const settings = {
    apiBaseUrl: getSetting('REACT_APP_API_BASE_URL'),
    webPushNotificationPublicKey: getSetting('REACT_APP_WEB_PUSH_NOTIFICATION_PUBLIC_KEY'),
};

export default settings;