import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useNavigate } from "react-router-dom";
import { fetchPlayerInfo } from "../../actions/staff/clubPlayerActions";
import WeeklyChart from "../common/WeeklyChart";
import NotificationMessage from "../../components/notification/NotificationMessage";
import ThemeContext from "../../theme/ThemeContext";
import TaskOverview from '../../components/taskoverview/TaskOverview';
import { useTranslation } from 'react-i18next';
import { formatDate, formatBirth } from '../../components/dateUtils';
import asset from '../../img/asset.png';
import { Container } from "react-bootstrap";

const PlayerHomeScreen = () => {
  const { playerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log(userInfo);

  const { loading, error, playerInfo } = useSelector(
    (state) => state.playerInfo
  );

  console.log(playerInfo)

  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;


  useEffect(() => {
    dispatch(fetchPlayerInfo(userInfo.club, playerId));
  }, [dispatch, playerId]);

  // Generisanje datuma za poslednjih sedam dana
  const currentDate = new Date();
  const lastSevenDays = [...Array(7).keys()].map((days) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() - days);
    return date.toISOString().slice(0, 10); // Formatiranje u "YYYY-MM-DD" formatu
  });

  // Generisanje podataka za grafikone
  const physicalData = playerInfo
    ? lastSevenDays.map((date, index) => ({
      date,
      rating: playerInfo.weeklyPhysicalHistory[index],
    }))
    : [];

  const mentalData = playerInfo
    ? lastSevenDays.map((date, index) => ({
      date,
      rating: playerInfo.weeklyMentalHistory[index],
    }))
    : [];

  // Stil za tablu
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "50px",
   
  };
  const containerStyleo = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
    marginTop: "50px",
  };


  const descriptionStyle = {
    fontSize: "14px",

    fontStyle: "italic",
    padding: "10px",
    backgroundColor: "rgb(17,63,78)",
    borderRadius: "5px",

    marginBottom: "15px",
  };

  const titleStyle = {
    fontSize: "18px",
    fontWeight: "bold",

    marginBottom: "5px",
  };

  const tableStyle = {
    borderCollapse: "collapse",

    fontFamily: fonts.primary,
    color: colors.fontcolor,
    backgroundColor: colors.background,
    backgroundImage: `url(${asset})`, // Postavljanje pozadine
    backgroundBlendMode: "darken", // Blend mode koji kombinuje sliku sa bojom
    filter: "contrast(1.2) brightness(0.8)", // Povećanje kontrasta na 120%
    backgroundSize: "cover", // Slika će pokriti ceo element
    backgroundPosition: "center", // Centriranje slike
    backgroundRepeat: "no-repeat", // Sprečava ponavljanje slike
    margin: "20px 0",
    textAlign: "left",
    minWidth: "380px", // Minimalna širina
    minHeight: "300px", // Minimalna visina
  };

  const tdStyle = {
    padding: "10px",

  };

  const handleTaskClick = (taskId) => {
    // Navigacija na željenu rutu
    navigate(`/club/${userInfo.club}/scheduledtask/${taskId}`);
  };
  const calculateSleepDuration = (bedTimeStr, wakeTimeStr) => {
    if (bedTimeStr === "not filled" || wakeTimeStr === "not filled") return "not filled";

    const bedTime = new Date(bedTimeStr);
    let wakeTime = new Date(wakeTimeStr);

    // Adjust wake time if it is earlier than bed time (assume it's the next day)
    if (wakeTime <= bedTime) {
      wakeTime.setDate(wakeTime.getDate() + 1);
    }

    const durationMs = wakeTime - bedTime;
    const durationHours = Math.floor(durationMs / (1000 * 60 * 60));
    const durationMinutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${durationHours} hours and ${durationMinutes} minutes`;
  };

  const bedTimeStr = playerInfo && playerInfo.dailyStatusesWeeklyOverview && playerInfo.dailyStatusesWeeklyOverview.length > 0
    ? playerInfo.dailyStatusesWeeklyOverview[playerInfo.dailyStatusesWeeklyOverview.length - 1].bedTime
    : "not filled";

  const wakeTimeStr = playerInfo && playerInfo.dailyStatusesWeeklyOverview && playerInfo.dailyStatusesWeeklyOverview.length > 0
    ? playerInfo.dailyStatusesWeeklyOverview[playerInfo.dailyStatusesWeeklyOverview.length - 1].wakeTime
    : "not filled";
  return (
    <div style={{ paddingBottom: "50px" }}>
      <h2 style={containerStyle}>{t("Player Information").toUpperCase()}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        playerInfo && (
          <div>
            <div style={containerStyle}>
              <table style={tableStyle}>
                <tbody>
                  <tr>
                    <td style={tdStyle}>{t("Name")}:</td>
                    <td style={tdStyle}>{playerInfo.name}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Surname")}:</td>
                    <td style={tdStyle}>{playerInfo.surname}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Age")}:</td>
                    <td style={tdStyle}>
                      {playerInfo.age && playerInfo.age !== "not filled" ? formatBirth(playerInfo.age) : "N/A"}
                    </td>

                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Position")}:</td>
                    <td style={tdStyle}>{playerInfo.position}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Jersey number")}:</td>
                    <td style={tdStyle}>{playerInfo.jersynum}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Nationality")}:</td>
                    <td style={tdStyle}>{playerInfo.nationality}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Passport expiration date")}:</td>

                    <td style={tdStyle}>
                      {playerInfo.passportExpirationDate && playerInfo.passportExpirationDate !== "not filled"
                        ? formatBirth(playerInfo.passportExpirationDate)
                        : "N/A"}
                    </td>

                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Fitnessstatus")}:</td>
                    <td style={tdStyle}>
                      {playerInfo?.dailyStatusesWeeklyOverview?.length > 0
                        ? playerInfo.dailyStatusesWeeklyOverview[
                          playerInfo.dailyStatusesWeeklyOverview.length - 1
                        ].physicalStatus
                        : "not filled"}
                    </td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Mentalstatus")}:</td>
                    <td style={tdStyle}>
                      {playerInfo?.dailyStatusesWeeklyOverview?.length > 0
                        ? playerInfo.dailyStatusesWeeklyOverview[
                          playerInfo.dailyStatusesWeeklyOverview.length - 1
                        ].mentalStatus
                        : "not filled"}
                    </td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("SleepTime")}:</td>
                    <td style={tdStyle}>{calculateSleepDuration(bedTimeStr, wakeTimeStr)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("SleepQuality")}:</td>
                    <td style={tdStyle}>
                      {playerInfo?.dailyStatusesWeeklyOverview?.length > 0
                        ? playerInfo.dailyStatusesWeeklyOverview[
                          playerInfo.dailyStatusesWeeklyOverview.length - 1
                        ].sleepStatus
                        : "not filled"}
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

            <div style={{

              maxWidth: "500px",
              margin: "0 auto",
              borderRadius: "8px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center", // Centriranje sadržaja
              textAlign: "center",  // Centriranje teksta unutar svakog elementa
            }}>
              <h6 style={titleStyle}>{t("Informacije o fizickom statusu:").toUpperCase()}</h6>
              <p style={descriptionStyle}>
                {playerInfo.dailyStatusesWeeklyOverview &&
                  playerInfo.dailyStatusesWeeklyOverview.length > 0 &&
                  playerInfo.dailyStatusesWeeklyOverview[playerInfo.dailyStatusesWeeklyOverview.length - 1].physicalStatusDescription
                  ? `"${playerInfo.dailyStatusesWeeklyOverview[playerInfo.dailyStatusesWeeklyOverview.length - 1].physicalStatusDescription}"`
                  : t("Nema opisa.")}
              </p>



              <h6 style={titleStyle}>{t("Informacije o mentalnom statusu:").toUpperCase()}</h6>
              <p style={descriptionStyle}>
                {playerInfo.dailyStatusesWeeklyOverview &&
                  playerInfo.dailyStatusesWeeklyOverview.length > 0 &&
                  playerInfo.dailyStatusesWeeklyOverview[playerInfo.dailyStatusesWeeklyOverview.length - 1].mentalStatusDescription
                  ? `"${playerInfo.dailyStatusesWeeklyOverview[playerInfo.dailyStatusesWeeklyOverview.length - 1].mentalStatusDescription}"`
                  : t("Nema opisa.")}
              </p>

            </div>

            {/* Prikaz grafikona za fizičku spremnost */}
            <h3
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
                marginTop: "60px",
              }}
            >
              {t("Physical State")}
            </h3>
            <WeeklyChart data={physicalData} />
            <h4
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
              }}
            >
              {t("Average evaluation")}: {playerInfo.averagePhysicalRating.toFixed(2)}
            </h4>

            {/* Prikaz grafikona za mentalnu snagu */}
            <h3
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
                marginTop: "60px",
              }}
            >
              {t("Mental Condition")}
            </h3>
            <WeeklyChart data={mentalData} />
            <h4
              style={{
                textAlign: "center",
                color: theme.colors.fontcolor,
                fontFamily: theme.fonts.primary,
                paddingBottom: '30px',
              }}
            >
              {t("Average evaluation")}: {playerInfo.averageMentalRating.toFixed(2)}
            </h4>

            {/* Prikaz notifikacija */}
            <div style={containerStyleo}>
              <h3
                style={{
                  textAlign: "center",
                  color: theme.colors.fontcolor,
                  fontFamily: theme.fonts.primary,
                  marginTop: "60px",
                }}
              >
                {t("Notifications")}
              </h3>
              {playerInfo.notifications.notifications.map((notification, index) => (
                <NotificationMessage
                  key={index}
                  assigned={notification.send_to.name}
                  time={formatDate(notification.createdAt)}
                  notification={notification.text}
                  style={{
                    // Stilovi za notifikaciju
                    padding: "8px",
                    border: `1px solid ${colors.primaryColor}`,
                    marginBottom: "10px",
                    backgroundColor: colors.background,
                    color: colors.fontcolor,
                  }}
                />
              ))}

            </div>
            <h2>{t("Assigned Tasks")}</h2>
            {playerInfo.assignedTasks.map((assignedTask) => (
              <TaskOverview
                key={assignedTask._id}
                task={assignedTask.name}
                description={assignedTask.description}
                time={formatDate(assignedTask.startTime)}
                location={assignedTask.location}
                scheduledTaskId={assignedTask._id}
                onTaskClick={handleTaskClick} //
              />
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default PlayerHomeScreen;
