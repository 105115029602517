import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlayers, resetPlayers } from "../../actions/staff/clubPlayerActions";
import { fetchTrainers } from '../../actions/staff/clubStaffActions';
import { createScheduledTask } from "../../actions/staff/scheduledActions";
import { fetchTasksList } from "../../actions/staff/tasksListActions";
import { useNavigate } from "react-router-dom";
import { RESET_PLAYERS } from '../../constants/staff/clubPlayerConstants';
import {CREATE_SCHEDULED_TASKS_RESET} from "../../constants/staff/scheduledListConstants";
import { fetchPlayersGroups } from '../../actions/staff/playersGroupsActions';
import Button from '../../components/cofButton/Button'
import moment from 'moment-timezone';

const ScheduledTaskCreateScreen = () => {
  const dispatch = useDispatch();  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const clubId = userInfo.club;
  const [taskLocation, setTaskLocation] = useState("");
  const [taskDuration, setTaskDuration] = useState("");
  const [taskType, setTaskType] = useState("");
  const [taskName, setTaskName] = useState("");
  const [addDeadline, setAddDeadline] = useState(false);
  const [addAttendanceConfirmation, setAddAttendanceConfirmation] =
    useState(false);
  const [deadlineDate, setDeadlineDate] = useState("");
  const [attendanceConfirmationDate, setAttendanceConfirmationDate] =
    useState("");
    const [startTime, setStartTime] = useState("");
    const [displayTime, setDisplayTime] = useState('');
    const [description, setDescription] = useState("");
    const [taskId, setTaskId] = useState("");
    const [startTimeError, setStartTimeError] = useState("");
    const [deadlineError, setDeadlineError] = useState("");
    const [attendanceConfirmationError, setAttendanceConfirmationError] = useState("");


  const { loading, error, trainers, success: successDelete } = useSelector(
    (state) => state.staff
  );
  console.log(trainers);

  const { playersGroups } = useSelector(
    (state) => state.playersGroups
  );
  console.log(playersGroups);
    useEffect(() => {
    dispatch(fetchPlayersGroups(userInfo.club));
  }, [dispatch, userInfo.club]);

  const { success: successCreate } = useSelector(
    (state) => state.createScheduledTask
  );


  useEffect(() => {
    if (successCreate) {
      navigate(`/tasks/scheduled/`);
      dispatch({type:CREATE_SCHEDULED_TASKS_RESET});
    }
  }, [dispatch,successCreate,navigate]);
  console.log(trainers);

  

  const { players  } = useSelector((state) => state.players );
  console.log(players);
  
  useEffect(() => {
    dispatch(fetchTasksList(clubId));
  }, [dispatch, clubId]);

  const { tasks } = useSelector((state) => state.tasks);
  console.log(tasks);

  useEffect(() => {
    // Ažurirajte unassignedPlayers kada se igrači dohvate
    if (players) {
      setUnassignedPlayers(players);
    }
  }, [players]);

  useEffect(() => {
    dispatch(fetchTrainers(userInfo.club));
  }, [dispatch]);

  useEffect(() => {
    if (playersGroups) {
      setUnassignedPlayerGroups(playersGroups);
    }
  }, [playersGroups]);

  const [assignedPlayers, setAssignedPlayers] = useState([]); // Prazan niz za assigned igrače
  const [unassignedPlayers, setUnassignedPlayers] = useState(players); // Puna lista igrača kao unassigned

  const [assignedTrainers, setAssignedTrainers] = useState([]);
  const [unassignedTrainers, setUnassignedTrainers] = useState(trainers);

  const [assignedPlayerGroups, setAssignedPlayerGroups] = useState([]);
const [unassignedPlayerGroups, setUnassignedPlayerGroups] = useState(playersGroups);

  console.log(assignedTrainers);

  
  
  

  
  
  const handleCreateTask = () => {

    if (!startTime) {
      setStartTimeError(t("You must fill in the start time")); // Poruka greške
      return;
    } else {
      setStartTimeError(""); // Očisti grešku ako je startTime validan
    }

    if (addDeadline && !deadlineDate) {
      setDeadlineError(t("You must enter a deadline date."));
      return;
    } else {
      setDeadlineError(""); // Clear error if deadline is filled
    }

    if (addAttendanceConfirmation && !attendanceConfirmationDate) {
      setAttendanceConfirmationError(t("You must fill in the attendance confirmation date"));
      return; // Prekid funkcije ako datum nije unet
    } else {
      setAttendanceConfirmationError(""); // Očisti grešku ako je datum unet
    }

    if (addDeadline && new Date(deadlineDate) < new Date(startTime)) {
      console.log("Deadline date cannot be set before start date.");
      return;
    }
  
    // Provera da li je zadato vreme potvrde prisustva posle početka zadatka
    if (addAttendanceConfirmation && new Date(attendanceConfirmationDate) > new Date(startTime)) {
      console.log("Attendance confirmation date cannot be set after start date.");
      return;
    }
  
    // Provera da li je zadato vreme potvrde prisustva posle vremena roka
    if (addAttendanceConfirmation && addDeadline && new Date(attendanceConfirmationDate) > new Date(deadlineDate)) {
      console.log("Attendance confirmation date cannot be set after deadline date.");
      return;
    }
  
    // Provera da li je zadato vreme roka pre vremena potvrde prisustva
    if (addAttendanceConfirmation && addDeadline && new Date(deadlineDate) < new Date(attendanceConfirmationDate)) {
      console.log("Deadline date cannot be set before attendance confirmation date.");
      return;
    }
  
    const taskData = {
      taskid: taskId,
      startTime: startTime,
      description: description,
      assignedTo: assignedPlayers,
      staffAssignedTo: assignedTrainers,
      groupsAssignedTo: assignedPlayerGroups.map((group) => group._id),
      assignedBy: userInfo.name + " " + userInfo.surname,
      location: taskLocation,
      name: taskName,
      duration: taskDuration,
      deadline: addDeadline ? new Date(deadlineDate) : null,
      attendanceConfirmationUntil: addAttendanceConfirmation
        ? new Date(attendanceConfirmationDate)
        : null,
    };

    console.log("taskData before dispatch:", taskData);

    if (addDeadline && !deadlineDate) {
      console.log("Morate uneti datum za deadline.");
      return;
    }

    if (addAttendanceConfirmation && !attendanceConfirmationDate) {
      console.log("Morate uneti datum za potvrdu prisustva.");
      return;
    }

    console.log(taskData);
    dispatch(createScheduledTask(clubId, taskData));
    
  };


  const handlePlayerMove = (player) => {
    if (unassignedPlayers.includes(player)) {
      setUnassignedPlayers(unassignedPlayers.filter((p) => p !== player));
      setAssignedPlayers([...assignedPlayers, player]);
    } else if (assignedPlayers.includes(player)) {
      setAssignedPlayers(assignedPlayers.filter((p) => p !== player));
      setUnassignedPlayers([...unassignedPlayers, player]);
    } else {
      console.error("Player not found in assigned or unassigned list.");
    }
  };

  const handleTrainerMove = (trainer) => {
    if (unassignedTrainers.includes(trainer)) {
      setUnassignedTrainers(unassignedTrainers.filter((t) => t !== trainer));
      setAssignedTrainers([...assignedTrainers, trainer]);
    } else if (assignedTrainers.includes(trainer)) {
      setAssignedTrainers(assignedTrainers.filter((t) => t !== trainer));
      setUnassignedTrainers([...unassignedTrainers, trainer]);
    } else {
      console.error("Trainer not found in assigned or unassigned list.");
    }
  };

  const handlePlayerGroupMove = (group) => {
    if (unassignedPlayerGroups.includes(group)) {
      // Ukloni grupu iz unassigned i dodaj u assigned
      setUnassignedPlayerGroups(unassignedPlayerGroups.filter((g) => g !== group));
      setAssignedPlayerGroups([...assignedPlayerGroups, group]);
  
      // Ukloni članove grupe iz unassignedPlayers
      const groupPlayerIds = group.members.map(({ member }) => member._id);
      setUnassignedPlayers((prevPlayers) =>
        prevPlayers.filter((player) => !groupPlayerIds.includes(player._id))
      );
    } else if (assignedPlayerGroups.includes(group)) {
      // Ukloni grupu iz assigned i vrati u unassigned
      setAssignedPlayerGroups(assignedPlayerGroups.filter((g) => g !== group));
      setUnassignedPlayerGroups([...unassignedPlayerGroups, group]);
  
      // Vrati članove grupe u unassignedPlayers, ali proveri da nisu već tu
      const groupPlayers = group.members.map(({ member }) => member);
      setUnassignedPlayers((prevPlayers) => [
        ...prevPlayers.filter((player) => !groupPlayers.some((gp) => gp._id === player._id)), // Filtriraj igrače koji su već tu
        ...groupPlayers, // Dodaj nove igrače
      ]);
    } else {
      console.error("PlayerGroup not found in assigned or unassigned list.");
    }
  };
  
  const handleRemovePlayerGroup = (group) => {
    setAssignedPlayerGroups(assignedPlayerGroups.filter((g) => g !== group));
    setUnassignedPlayerGroups([...unassignedPlayerGroups, group]);
  
    // Vrati članove grupe u unassignedPlayers, ali proveri da nisu već tu
    const groupPlayers = group.members.map(({ member }) => member);
    setUnassignedPlayers((prevPlayers) => [
      ...prevPlayers.filter((player) => !groupPlayers.some((gp) => gp._id === player._id)), // Filtriraj igrače koji su već tu
      ...groupPlayers, // Dodaj nove igrače
    ]);
  };
  
  
  

  const handleTaskSelect = (selectedTaskId) => {
    const selectedTask = tasks.find((task) => task._id === selectedTaskId);
    if (selectedTask) {
      setTaskType(selectedTask.taskType);
      setTaskName(selectedTask.name);
      setTaskLocation(selectedTask.location);
      setTaskDuration(selectedTask.duration);
      setDescription(selectedTask.description);
    }
  };
  useEffect(() => {
    // Inicijalno postavljanje podataka izabranog zadatka
    if (taskId) {
      handleTaskSelect(taskId);
    }
  }, [taskId]);

  const handleRemovePlayer = (player) => {
    setAssignedPlayers(assignedPlayers.filter((p) => p !== player));
    setUnassignedPlayers([...unassignedPlayers, player]);
  };

  useEffect(() => {
    // Ažurirajte unassignedTrainers kada se treneri dohvate
    if (trainers) {
      setUnassignedTrainers(trainers);
    }
  }, [trainers]);
  
  const handleRemoveTrainer = (trainer) => {
    setAssignedTrainers(assignedTrainers.filter((t) => t !== trainer));
    setUnassignedTrainers([...unassignedTrainers, trainer]);
  };
  
  
  useEffect(() => {
      dispatch(resetPlayers());
      dispatch(fetchPlayers(userInfo.club));
    
  }, [userInfo.club, dispatch]);

  const currentDateTime = new Date().toISOString().slice(0, -8);

  const handleStartTimeChange = (e) => {
    const value = e.target.value;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeWithZone = moment.tz(value, userTimeZone);
    const formattedTime = timeWithZone.format();
    console.log("Formatted Time: ", formattedTime);
    setDisplayTime(value);
    setStartTime(formattedTime);
  };


  // Funkcije za selektovanje svih igrača i trenera
const handleSelectAllPlayers = () => {
  if (unassignedPlayers.length > 0) {
    setAssignedPlayers([...assignedPlayers, ...unassignedPlayers]);
    setUnassignedPlayers([]);
  } else {
    setUnassignedPlayers([...unassignedPlayers, ...assignedPlayers]);
    setAssignedPlayers([]);
  }
};

const handleSelectAllTrainers = () => {
  if (unassignedTrainers.length > 0) {
    setAssignedTrainers([...assignedTrainers, ...unassignedTrainers]);
    setUnassignedTrainers([]);
  } else {
    setUnassignedTrainers([...unassignedTrainers, ...assignedTrainers]);
    setAssignedTrainers([]);
  }
};


  return (
    <div className="mx-2">
    <Form>
    <Form.Group controlId="Pocetak" className="my-2">
    <Form.Label>{t("StartTime")}</Form.Label>
    <Form.Control
          type="datetime-local"
          onChange={handleStartTimeChange}
          min={currentDateTime}
          value={displayTime}
          style={{ backgroundColor: "#415f74", borderColor: "white", borderRadius: "0", color: "white", opacity: "0.6" }}
        />
    <small  style={{ color: 'white' }}>{t("EnterStartTimeFormat")}</small>
  </Form.Group>
     
      <Form.Group controlId="Vrsta zadatka" className="my-2">
        <Form.Control
          as="select"
          placeholder={t("SelectType")}
          onChange={(e) => setTaskId(e.target.value)}
          value={taskId}
          style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
        >
          <option>{t("SelectTask")}</option>
          {tasks.map((task) => (
            <option key={task._id} value={task._id}>
              {task.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      
      

      <Form.Group controlId="Tip zadatka" className="my-2">
      <Form.Control
        as="select"
        onChange={(e) => setTaskType(e.target.value)}
        value={taskType}
        style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
      >
        <option value="">{t("SelectType")}</option>
        <option value="TRAINING">{t("TRAINING")}</option>
        <option value="MATCHDAY">{t("MATCHDAY")}</option>
        <option value="DINNER">{t("DINNER")}</option>
        <option value="QUARANTINE">{t("QUARANTINE")}</option>
        <option value="TEAMBUILDING">{t("TEAMBUILDING")}</option>
        <option value="FITNESS_TRAINING">{t("FITNESS_TRAINING")}</option>
      </Form.Control>
    </Form.Group>


    <Form.Group controlId="Opis" className="my-2">
    <Form.Control
      type="text"
      placeholder={t("Description")}
      onChange={(e) => setDescription(e.target.value)}
      value={description}
      style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
    />
  </Form.Group>
    <Form.Group controlId="Ime zadatka" className="my-2">
      <Form.Control
        type="text"
        placeholder={t("EnterTaskName")}
        onChange={(e) => setTaskName(e.target.value)}
        value={taskName}
        style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
      />
    </Form.Group>
    <Form.Group controlId="Lokacija zadatka" className="my-2">
      <Form.Control
        type="text"
        placeholder={t("EnterTaskLocation")}
        onChange={(e) => setTaskLocation(e.target.value)}
        value={taskLocation}
        style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
      />
    </Form.Group>
    <Form.Group controlId="Trajanje zadatka" className="my-2">
      <Form.Control
        type="text"
        placeholder={t("EnterTaskDuration")}
        onChange={(e) => setTaskDuration(e.target.value)}
        value={taskDuration}
        style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
      />
    </Form.Group>


      <Form.Check
        type="checkbox"
        label="Add Deadline"
        checked={addDeadline}
        onChange={() => setAddDeadline(!addDeadline)}
      />
      {addDeadline && (
         <Form.Group controlId="Deadline" className="my-2">
         <Form.Label>{t("DeadlineDate")}</Form.Label>
         <Form.Control
           type="datetime-local"
           min={startTime}
           onChange={(e) => {
             const selectedDeadlineDate = e.target.value;
             setDeadlineDate(selectedDeadlineDate);
     
             // Provera da li je zadato vreme roka pre vremena početka zadatka
             if (new Date(selectedDeadlineDate) < new Date(startTime)) {
               console.log("Deadline date cannot be set before start date.");
               setDeadlineDate(""); // Resetovanje vrednosti ako nije u skladu sa zahtevima
             }
             
             // Provera da li je zadato vreme roka pre vremena potvrde prisustva
             if (addAttendanceConfirmation && new Date(selectedDeadlineDate) < new Date(attendanceConfirmationDate)) {
               console.log("Deadline date cannot be set before attendance confirmation date.");
               setDeadlineDate(""); // Resetovanje vrednosti ako nije u skladu sa zahtevima
             }
           }}
           value={deadlineDate}
           style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
         />
       </Form.Group>
     )}
     
      
      <Form.Check
        type="checkbox"
        label={t("AddAttendanceConfirmation")}
        checked={addAttendanceConfirmation}
        onChange={() => setAddAttendanceConfirmation(!addAttendanceConfirmation)}
       
      />
      {addAttendanceConfirmation && (
        <Form.Group controlId="AttendanceConfirmation" className="my-2">
        <Form.Label>{t("AttendanceConfirmationDate")}</Form.Label>
        <Form.Control
          type="datetime-local"
          max={startTime}
          onChange={(e) => {
            const selectedConfirmationDate = e.target.value;
            setAttendanceConfirmationDate(selectedConfirmationDate);
    
            // Provera da li je zadato vreme potvrde prisustva posle početka zadatka
            if (new Date(selectedConfirmationDate) > new Date(startTime)) {
              console.log("Attendance confirmation date cannot be set after start date.");
              setAttendanceConfirmationDate(""); // Resetovanje vrednosti ako nije u skladu sa zahtevima
            }
            
            // Provera da li je zadato vreme roka pre vremena potvrde prisustva
            if (addDeadline && new Date(deadlineDate) < new Date(selectedConfirmationDate)) {
              console.log("Attendance confirmation date cannot be set after deadline date.");
              setAttendanceConfirmationDate(""); // Resetovanje vrednosti ako nije u skladu sa zahtevima
            }
          }}
          value={attendanceConfirmationDate}
          style={{backgroundColor:"#415f74", borderColor:"white", borderRadius:"0", color:"white", opacity:"0.6"}}
        />
      </Form.Group>
      )}
    </Form>
      <div>
      <h4>{t("Unassigned PlayerGroups")}</h4>
<ul style={{ listStyleType: "none", padding: 0 }}>
  {unassignedPlayerGroups.map((group) => (
    <li key={group._id} style={{ marginBottom: "15px" }}>
      <label>
        <input
          type="checkbox"
          onChange={() => handlePlayerGroupMove(group)}
          checked={assignedPlayerGroups.includes(group)}
        />
        {group.name}
      </label>
      <details>
        <summary>{t("View Players in Group")}</summary>
        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
          {group.members.map(({ member }) => (
            <li key={member._id}>
              {member.name} {member.surname}
            </li>
          ))}
        </ul>
      </details>
    </li>
  ))}
</ul>

<h4>{t("Assigned PlayerGroups")}</h4>
<ul style={{ listStyleType: "none", padding: 0 }}>
  {assignedPlayerGroups.map((group) => (
    <li key={group._id} style={{ marginBottom: "15px" }}>
      <label>
        {group.name}
      </label>
      <button
        onClick={() => handleRemovePlayerGroup(group)}
        style={{
         
          border: "none",
          padding: "5px 10px",
          cursor: "pointer",
          marginLeft: "10px",
        }}
      >
        {t("Remove")}
      </button>
      <details>
        <summary>{t("View Players in Group")}</summary>
        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
          {group.members.map(({ member }) => (
            <li key={member._id}>
              {member.name} {member.surname}
            </li>
          ))}
        </ul>
      </details>
    </li>
  ))}
</ul>

  <div>
  <h4>{t("Unassigned Players")}</h4>
  <label>
    <input
      type="checkbox"
      onChange={handleSelectAllPlayers}
      checked={unassignedPlayers.length === 0 && assignedPlayers.length > 0}
    />
    {t("Select All Players")}
  </label>
  <ul>
    {unassignedPlayers.map((player) => (
      <li key={player.id}>
        {player.name}&nbsp;{player.surname}{" "}
        <input
          type="checkbox"
          onChange={() => handlePlayerMove(player)}
          checked={assignedPlayers.includes(player)}
        />
      </li>
    ))}
  </ul>

        <h4>{t("AssignedPlayers")}</h4>
        <ul>
          {assignedPlayers.map((player) => (
            <li key={player.id}>
              {player.name}&nbsp;{player.surname}{" "}
              <button onClick={() => handleRemovePlayer(player)}>{t("Remove")}</button>
            </li>
          ))}
        </ul>
      </div>
       {/* Prikazivanje liste igrača i trenera */}
       <div>
       <h4>{t("Unassigned Trainers")}</h4>
  <label>
    <input
      type="checkbox"
      onChange={handleSelectAllTrainers}
      checked={unassignedTrainers.length === 0 && assignedTrainers.length > 0}
    />
    {t("Select All Trainers")}
  </label>
  <ul>
    {unassignedTrainers.map((trainer) => (
      <li key={trainer.id}>
        {trainer.name}&nbsp;{trainer.surname}{" "}
        <input
          type="checkbox"
          onChange={() => handleTrainerMove(trainer)}
          checked={assignedTrainers.includes(trainer)}
        />
      </li>
    ))}
  </ul>

        <h4>{t("AssignedTrainers")}</h4>
        <ul>
          {assignedTrainers.map((trainer) => (
            <li key={trainer.id}>
              {trainer.name}&nbsp;{trainer.surname}{" "}
              <button onClick={() => handleRemoveTrainer(trainer)}>{t("Remove")}</button>
            </li>
          ))}
        </ul>
      </div>
</div>
{attendanceConfirmationError && (
  <div style={{ color: "red", marginBottom: "10px" }}>
    {attendanceConfirmationError}
  </div>
)}

{startTimeError && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          {startTimeError}
        </div>
      )}

<div className="my-2" style={{ color: "red" }}>
  {deadlineError && <p>{deadlineError}</p>}
</div>
      <Button variant="primary" onClick={handleCreateTask} className="rounded-0 border-0">
        {t("SaveTask")}
      </Button>
    </div>
  );
};

export default ScheduledTaskCreateScreen;
