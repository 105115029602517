import axios from "axios";
import {
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS
} from '../constants/resetPasswordConstants'

export const resetPassword = (email) => async (dispatch, getState) =>{
  console.log("Poziva se resetPassword akcija, email: " + email)
    try {
     dispatch({
         type: RESET_PASSWORD_REQUEST,
     });

     const requestData = {
        email: email,
    };
      console.log("Poziva se API za resetPassword link")
       const { data } = await axios.post(
       `/api/resetpassword/resetpasswordrequest`,
         requestData
       );
     
       dispatch({
         type: RESET_PASSWORD_SUCCESS,
         payload: data,
       });
 
    } catch (error) {
      console.log("Poziva se catchError za resetPassword akciju: " + error)
     dispatch({
       type: RESET_PASSWORD_FAIL,
       payload:
         error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
     });
   }
 
 }