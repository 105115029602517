import React, { useState } from 'react';
import { Table as BootstrapTable, Button } from 'react-bootstrap';
import './DataTable.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from '../../components/dateUtils'; // Uvoz funkcije za formatiranje datuma

const DataTable = ({
  columns,
  data,
  actions,
  actionLabels,
  buttonStyle,
  actionHandlers,
  statusStyleGetter,
  handleSortBySelect,
}) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const { t } = useTranslation();

  const handleColumnClick = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortData = (data, column, order) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }
      return 0;
    });
    return sortedData;
  };

  const sortDataByDate = (data, order) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.pocetak);
      const dateB = new Date(b.pocetak);
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    });
    return sortedData;
  };

  const sortedData =
    sortColumn === 'pocetak'
      ? sortDataByDate(data, sortOrder)
      : sortColumn
        ? sortData(data, sortColumn, sortOrder)
        : data;

  const lastColumnStyle = {
    borderBottom: 'none',  // Dodajte stil za uklanjanje donje ivice
    marginBottom: 0,        // Dodajte stil za uklanjanje dodatnog prostora na dnu
  };

  return (
    <div>
      <BootstrapTable className="data-table" striped bordered hover responsive >
        <thead>
          <tr className="odd-row">
            {columns.map((column, index) => (
              <th
                key={index}
                onClick={() => handleColumnClick(column.dataKey)}
                className={sortColumn === column.dataKey ? 'sorted' : ''}
                style={{
                  backgroundColor: 'rgba(8, 33, 76, 0.1)',
                  color: '#fff',
                  borderColor: 'rgba(0,0,0,0)',
                  border: '3px solid transparent',
                }}
              >
                <span>{column.label}</span>
                {sortColumn === column.dataKey && (
                  <span className="sort-icon">
                    {sortOrder === 'asc' ? '▲' : '▼'}
                  </span>
                )}
                {column.label === 'Datum' && (
                  <select onChange={(e) => handleSortBySelect(e.target.value)}>
                    <option value="asc">Rastuće</option>
                    <option value="desc">Opadajuće</option>
                  </select>
                )}
              </th>
            ))}
            {actions.length > 0 && (
              <th style={{
                width: `${actions.length * 100}px`, color: '#FFFFFF', backgroundColor: 'rgba(8, 33, 76, 0.1)', borderColor: 'rgba(0,0,0,0)',
                border: '3px solid transparent',
              }}>{t("Actions").toUpperCase()}</th>
            )}
          </tr>
        </thead>
        <tbody>
  {sortedData.map((row, rowIndex) => (
    <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'even-row' : 'odd-row'}>
      {columns.map((column, columnIndex) => (
        <td
          key={columnIndex}
          className={
            column.dataKey === 'status' && statusStyleGetter
              ? statusStyleGetter(row)
              : ''
          }
          style={{
            color: column.dataKey === 'status' && statusStyleGetter
              ? undefined // Oslanjamo se na CSS klase za stilizaciju
              : '#fff',
            border: '4px solid transparent',
          }}
        >
          {column.format && typeof row[column.dataKey] === 'string'
            ? column.format(row[column.dataKey])
            : row[column.dataKey]}
        </td>
      ))}
      {actions.length > 0 && (
        <td
          className="icon-column"
          style={{
            backgroundColor:
              rowIndex % 2 === 0
                ? 'rgba(29, 78, 91, 0.1)'
                : 'rgba(8, 33, 76, 0.1)',
            border: '4px solid transparent',
          }}
        >
          {actions.map((action, index) => (
            <Button
              key={index}
              variant={action.variant}
              style={{ ...buttonStyle, ...action.style }}
              onClick={() => {
                if (
                  actionHandlers &&
                  actionHandlers[actionLabels[index]]
                ) {
                  actionHandlers[actionLabels[index]](row._id);
                }
              }}
            >
              <FontAwesomeIcon icon={action.icon} />
            </Button>
          ))}
        </td>
      )}
    </tr>
  ))}
</tbody>

      </BootstrapTable>
    </div>
  );
};

export default DataTable;
