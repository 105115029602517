import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchStaffMember } from "../../actions/staff/clubStaffActions";
import NotificationMessage from "../../components/notification/NotificationMessage";
import TaskOverview from "../../components/taskoverview/TaskOverview";
import ThemeContext from "../../theme/ThemeContext";
import { useTranslation } from 'react-i18next';
import asset from '../../img/asset.png'
import { formatDate, formatBirth } from '../../components/dateUtils';
import { Row, Container  } from 'react-bootstrap';

const StaffMemberScreen = () => {
  const { t } = useTranslation();
  const { staffMemberId } = useParams();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const staffMemberInfo = useSelector((state) => state.staffMember);
  const { loading, error, staffMember } = staffMemberInfo;
  console.log(staffMember);
  useEffect(() => {
    dispatch(fetchStaffMember(userInfo.club, staffMemberId));
  }, [dispatch, staffMemberId, userInfo.club]);
  console.log(staffMember);
  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;



  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
    marginTop: "50px",
  };

  const formatDate = (createdAt) => {
    return new Date(createdAt).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };



  const containerStyleo = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "50px",
  };

  const tableStyleo = {
    borderCollapse: "collapse",

    fontFamily: fonts.primary,
    color: colors.fontcolor,
    backgroundColor: colors.background,
    backgroundImage: `url(${asset})`, // Postavljanje pozadine
    backgroundSize: "cover", // Slika će pokriti ceo element
    backgroundPosition: "center", // Centriranje slike
    backgroundRepeat: "no-repeat", // Sprečava ponavljanje slike
    backgroundBlendMode: "darken", // Blend mode koji kombinuje sliku sa bojom
    filter: "contrast(1.2) brightness(0.8)", // Povećanje kontrasta na 120%
    margin: "20px 0",
    textAlign: "left",
    minWidth: "380px", // Minimalna širina
    minHeight: "300px", // Minimalna visina
  };

  const tdStyle = {
    padding: "10px",

  };


  return (
    <div>
      <h2 style={containerStyle}>{t("Staff Info").toUpperCase()}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        staffMember && (
          <div style={containerStyle}>
            <div style={containerStyleo}>
              <table style={tableStyleo}>
                <tbody>
                  <tr>
                    <td style={tdStyle}>{t("Name")}:</td>
                    <td style={tdStyle}>{staffMember.name}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Surname")}:</td>
                    <td style={tdStyle}>{staffMember.surname}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("email")}:</td>
                    <td style={tdStyle}>{staffMember.email}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("birthdate")}:</td>
                    <td style={tdStyle}>{formatBirth(staffMember.birthdate)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Nationality")}:</td>
                    <td style={tdStyle}>{staffMember.passportNationality}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Passport expiration date")}:</td>
                    <td style={tdStyle}>
                      {staffMember.passportExpirationDate && staffMember.passportExpirationDate !== "not filled"
                        ? formatBirth(staffMember.passportExpirationDate)
                        : "N/A"}
                    </td>

                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Position")}:</td>
                    <td style={tdStyle}>{t(staffMember.position)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}>{t("Education")}</td>
                    <td style={tdStyle}>{staffMember.education}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Prikaz notifikacija */}
            <div style={containerStyle}>
              <h3
                style={{
                  textAlign: "center",
                  color: colors.fontcolor,
                  fontFamily: fonts.primary,
                  marginTop: "60px",
                }}
              >
                {t("Notifications")}
              </h3>
              {staffMember.notifications && staffMember.notifications.notifications.length > 0 ? (
                staffMember.notifications.notifications.map((notification, index) => (
                  <NotificationMessage
                    key={notification._id} // Koristi _id kao ključ jer je jedinstven
                    assigned={notification.send_to?.name || staffMember.name} // Ako nema send_to.name, koristi ime člana
                    time={formatDate(notification.createdAt)} // Formatiranje vremena koristeći formatDate funkciju
                    notification={notification.text} // Prikaz teksta notifikacije
                    style={{
                      padding: "8px",
                      border: `1px solid ${colors.primaryColor}`,
                      marginBottom: "10px",
                      backgroundColor: colors.background,
                      color: colors.fontcolor,
                    }}
                  />
                ))
              ) : (
                <p style={{ color: colors.fontcolor }}>
                  {t("There are no notifications")}
                </p>
              )}
            </div>

            {/* Prikaz dodijeljenih zadataka */}
            <Container className="mx-auto w-100" style={{maxWidth:"730px"}}>
              <h2 style={{ color: colors.fontcolor }}>{t("Assigned Tasks")}</h2>
              {staffMember.assignedTasks && staffMember.assignedTasks.length > 0 ? (
                staffMember.assignedTasks.map((assignedTask) => {
                  // Provera da li zadatak ima ime, opis ili lokaciju
                  const taskName = assignedTask.name || "Unnamed Task";
                  const taskDescription = assignedTask.description || "No description available";
                  const taskLocation = assignedTask.location || "No location specified";
                  const taskTime = assignedTask.startTime ? formatDate(assignedTask.startTime) : "No time specified";

                  return (
                    <TaskOverview
                      key={assignedTask._id}
                      task={taskName}
                      description={taskDescription}
                      time={taskTime}
                      location={taskLocation}
                      scheduledTaskId={assignedTask._id}
                    />
                  );
                })
              ) : (
                <p style={{ color: colors.fontcolor }}>
                  {t("There are no assigned tasks")}
                </p>
              )}
            </Container>

          </div>
        )
      )}
    </div>
  );
};

export default StaffMemberScreen;
