import axios from "axios";
import {
    EDIT_PASSWORD_REQUEST,
    EDIT_PASSWORD_SUCCESS,
    EDIT_PASSWORD_FAIL,
    EDIT_PASSWORD_RESET
} from '../constants/resetPasswordConstants'
import { logout } from './userActions'
export const editPassword = (resetpasswordtoken,newPassword) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_PASSWORD_REQUEST,
      })
  
      console.log(resetpasswordtoken)
      console.log(newPassword)
      const { data } = await axios.post(`/api/resetpassword/${resetpasswordtoken}`,
      newPassword )
  
      dispatch({
        type: EDIT_PASSWORD_SUCCESS,
        payload: data,
      })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: EDIT_PASSWORD_FAIL,
        payload: message,
      })
    }
  }