import { React, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBRadio } from "mdb-react-ui-kit";
import TextBox from "../../components/common/TextBox";
import Button from "../../components/cofButton/Button";
import { useDispatch, useSelector } from "react-redux";
import { getUserWeeklyoverview } from "../../actions/weeklyoverviewActions";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { submitDailyStatus } from "../../actions/submitActions";
import { submitDailyStatusAgain } from "../../actions/submitAgainActions";
import { getDailyStatus } from "../../actions/dailyStatusActions";
import { useTranslation } from 'react-i18next';
import { SUBMIT_AGAIN_RESET } from "../../constants/submitAgainConstants";
import { DAILY_STATUS_RESET } from "../../constants/dailyStatusConstants";
import './Daily.css';
const DailySurveyScreen = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [mentalDetails, setMentalDetails] = useState("");
  const [fitnessDetails, setFitnessDetails] = useState("");
  const [bedTimeHour, setBedTimeHour] = useState("00");
  const [bedTimeMinute, setBedTimeMinute] = useState("00");
  const [wakeTimeHour, setWakeTimeHour] = useState("00");
  const [wakeTimeMinute, setWakeTimeMinute] = useState("00");

  // Kombinovanje bedTime i wakeTime u format "HH:mm"
  const bedTime = `${String(bedTimeHour).padStart(2, "0")}:${String(bedTimeMinute).padStart(2, "0")}`;
  const wakeTime = `${String(wakeTimeHour).padStart(2, "0")}:${String(wakeTimeMinute).padStart(2, "0")}`;

  const [showFitnessTextBox, setShowFitnessTextBox] = useState(false); // Make sure this is set to false
  const [showMentalTextBox, setShowMentalTextBox] = useState(false);
  const [showSleepTextBox, setShowSleepTextBox] = useState(false);
  const [isTextBoxFilled, setIsTextBoxFilled] = useState(true);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const weeklyoverview = useSelector((state) => state.weeklyoverview);
  const dailystatuses = weeklyoverview ? weeklyoverview.weeklyoverview : null;

  const dailystatus = useSelector((state) => state.dailyStatus);

  const submitStatus = useSelector((state) => state.submit);
  const { loading, error, success } = submitStatus

  const submitStatusAgain = useSelector((state) => state.submitAgain)
  const { loading: loadingAgain, error: errorAgain, success: successAgain } = submitStatusAgain


  const getDaily = useSelector((state) => state.dailyStatus)
  const { loading: loadingDaily, error: errorDaily, success: successDaily } = getDaily
  const [errorAlert, setErrorAlert] = useState(false);

  useEffect(() => {

    console.log(submitStatus)
    console.log('poziva se useEffect')
    if (success) {
      navigate(`/`);
      window.location.reload();


    } else if (error) {
      setErrorAlert(true);
    }
  }, [navigate, success, error]);

  // useEffect(() => {    
  //     console.log("Poziva se useEffect")
  //     console.log(trainerCreate)
  //     if (success) {
  //         navigate(`/club/${userInfo.club}/staff`);
  //         dispatch({ type: RESET_CREATE_TRAINER_STATE })
  //     } else if (error) {
  //         setErrorAlert(true);
  //     }
  // }, [success, error]);

  console.log(dailystatus)
  const todaystatus = dailystatus && dailystatus.dailystatus && dailystatus.dailystatus.length > 0 ? dailystatus.dailystatus[0] : null;
  console.log(todaystatus)

  const [fitnessStatus, setFitnessStatus] = useState('');
  const [mentalStatus, setMentalStatus] = useState('');
  const [sleepStatus, setSleepStatus] = useState('');

  console.log(fitnessStatus)
  console.log(fitnessStatus === "1")
  console.log(todaystatus ? todaystatus.physicalStatus : '')

  console.log(mentalStatus)
  console.log(todaystatus ? todaystatus.mentalStatus : '')
  console.log(mentalStatus === "4")

  console.log(userInfo)
  console.log(dailystatuses)
  console.log(weeklyoverview)
  useEffect(() => {
    console.log(`useEffect`)
    console.log(weeklyoverview)

    // Check if notifications are empty, if so, dispatch the action to fetch user notifications
    if (dailystatuses === null) {
      dispatch(getUserWeeklyoverview(userInfo._id));
    }
  }, [dispatch, userInfo._id, dailystatuses]);

  useEffect(() => {
    console.log(`useEffect`)
    if (todaystatus && todaystatus.physicalStatus) {
      setFitnessStatus(todaystatus.physicalStatus.toString());
    }
    if (todaystatus && todaystatus.mentalStatus) {
      setMentalStatus(todaystatus.mentalStatus.toString());
    }
    // Check if notifications are empty, if so, dispatch the action to fetch user notifications
    if (todaystatus === null || todaystatus.length === 0) {
      dispatch(getDailyStatus(userInfo._id));

    }
  }, [dispatch, userInfo._id, todaystatus]);

  const handleFitnessChange = (event) => {
    setFitnessStatus(event.target.value);
    setShowFitnessTextBox(Number(event.target.value) <= 3);
    setIsTextBoxFilled(Number(event.target.value) > 3);
  };

  const handleMentalChange = (event) => {
    setMentalStatus(event.target.value);
    setShowMentalTextBox(Number(event.target.value) <= 3);
    setIsTextBoxFilled(Number(event.target.value) > 3);
  };
  const handleSleepChange = (event) => {
    setSleepStatus(event.target.value);
    // setShowSleepTextBox(Number(event.target.value) <= 3);
    // setIsTextBoxFilled(Number(event.target.value) > 3);
  };
 
  

  // Handle changes in the fitness details text
  const handleFitnessDetailsChange = (event) => {
    setFitnessDetails(event.target.value);
    setSubmitButtonClicked(false); // Reset the Submit button clicked state
    setIsTextBoxFilled(!!event.target.value); // Use setFitnessDetails to update the fitnessDetails state
  };

  // Handle changes in the mental details text
  const handleMentalDetailsChange = (event) => {
    setMentalDetails(event.target.value);
    setSubmitButtonClicked(false); // Reset the Submit button clicked state
    setIsTextBoxFilled(!!event.target.value);// Use setMentalDetails to update the mentalDetails state
  };




  const handleSubmit = () => {

    // If the user selected 1, 2, or 3, and the corresponding textbox is not filled
    if ((showFitnessTextBox && fitnessStatus <= 3 && !fitnessDetails) || (showMentalTextBox && mentalStatus <= 3 && !mentalDetails)||( 
      !fitnessStatus || // Fitness status nije odabran
      !mentalStatus ||  // Mentalni status nije odabran
      !sleepStatus ||   // Kvalitet sna nije odabran
      !bedTime ||       // Vreme odlaska u krevet nije odabrano
      !wakeTime     ))    // Vreme buđenja nije odabrano)
    
      {
      setSubmitButtonClicked(true); // Show the notification for unfilled textbox
      alert("Molimo Vas da popunite sve obavezne informacije pre nego što nastavite.");

      return;
    }

    const requestBody = {
      physicalStatus: fitnessStatus,
      mentalStatus: mentalStatus,
      physicalDescription: fitnessDetails,
      mentalDescription: mentalDetails,
      bedTime: bedTime,
      wakeTime: wakeTime,
      sleepStatus: sleepStatus,
    };
    dispatch(submitDailyStatus(userInfo._id, requestBody));
    console.log('Data submitted successfully:');
    setIsSubmitSuccessful(true);


    
  };



  useEffect(() => {
    console.log(submitStatusAgain)
    console.log('poziva se useEffect')
    if (successAgain) {
      navigate(`/`);
      dispatch({ type: SUBMIT_AGAIN_RESET })
      dispatch({ type: DAILY_STATUS_RESET });
    } else if (errorAgain) {
      setErrorAlert(true);
    }
  }, [successAgain, errorAgain, successDaily, errorDaily]);


  const handleSubmitAgain = (e) => {
    e.preventDefault();
    // If the user selected 1, 2, or 3, and the corresponding textbox is not filled
    if ((showFitnessTextBox && fitnessStatus <= 3 && !fitnessDetails) || (showMentalTextBox && mentalStatus <= 3 && !mentalDetails)) {
      setSubmitButtonClicked(true); // Show the notification for unfilled textbox

      return;
    }


    const requestBodyNew = {
      physicalStatus: fitnessStatus,
      mentalStatus: mentalStatus,
      physicalDescription: fitnessDetails,
      mentalDescription: mentalDetails,

    };

    dispatch(submitDailyStatusAgain(userInfo._id, requestBodyNew));
    console.log('Data submitted successfully:');
    setIsSubmitSuccessful(true);

  };

  console.log(bedTime);
  console.log(wakeTime);
  

  if (todaystatus) {
    return (
      <div>
        <div className="text-center mt-5 ">
          <Row><h3>{t("Todays input").toUpperCase()}</h3></Row>
          <Row><h3>{t("Fitness status today")}: {todaystatus.physicalStatus}</h3></Row>
          <Row><h3>{t("Mental status today")}: {todaystatus.mentalStatus}</h3></Row>
          <Row><h3>{t("ocenakvalitetasna")}: {todaystatus.sleepStatus}</h3></Row>
        </div>

        <div>
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ paddingTop: "50px" }}
          >
            <Row>
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <h2>{t("Daily survey")}</h2>
              </Col>

              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <MDBContainer className="d-flex justify-content-center" style={{ width: "100%" }}>
                  <div className="mx-0 mx-sm-auto">
                    <form className=" mt-3" action="">
                      <p className="fw-bold text-center">
                        {t("How is your fitness status today")}?
                      </p>

                      <div className="text-center">
                        <MDBRadio
                          name="fitnessStatus"
                          id="flexRadioDefault1"
                          label="1"
                          inline
                          value="1"
                          onChange={handleFitnessChange}
                          defaultValue="1"
                          checked={fitnessStatus === "1"}

                        />
                        <MDBRadio
                          name="fitnessStatus"
                          id="flexRadioDefault2"
                          label="2"
                          inline
                          value="2"
                          onChange={handleFitnessChange}
                          defaultValue="2"
                          checked={fitnessStatus === "2"}

                        />
                        <MDBRadio
                          name="fitnessStatus"
                          id="flexRadioDefault3"
                          label="3"
                          inline
                          value="3"
                          onChange={handleFitnessChange}
                          defaultValue="3"
                          checked={fitnessStatus === "3"}

                        />
                        <MDBRadio
                          name="fitnessStatus"
                          id="flexRadioDefault4"
                          label="4"
                          inline
                          value="4"
                          onChange={handleFitnessChange}
                          defaultValue="4"
                          checked={fitnessStatus === "4"}

                        />
                        <MDBRadio
                          name="fitnessStatus"
                          id="flexRadioDefault5"
                          label="5"
                          inline
                          value="5"
                          onChange={handleFitnessChange}
                          defaultValue="5"
                          checked={fitnessStatus === "5"}

                        />
                      </div>
                    </form>
                    <div className="text-end fw-bold text-light">
                      <TextBox label={t("Fitness Details")} text={fitnessDetails}
                        showTextBox={showFitnessTextBox}
                        onInputChange={handleFitnessDetailsChange}
                        style={{ color: 'white' }}
                      />
                    </div>
                  </div>
                </MDBContainer>
              </Col>

              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <MDBContainer className="d-flex justify-content-center" style={{ width: "100%" }}>
                  <div className="mx-0 mx-sm-auto">
                    <form className=" mt-3" action="">
                      <p className="fw-bold text-center">
                        {t("How is your mental status today")}?
                      </p>

                      <div className="text-center">
                        <MDBRadio
                          name="mentalStatus"
                          id="flexRadioDefault1"
                          label="1"
                          inline
                          value="1"
                          onChange={handleMentalChange}
                          defaultValue="1"
                          checked={mentalStatus === "1"}

                        />
                        <MDBRadio
                          name="mentalStatus"
                          id="flexRadioDefault2"
                          label="2"
                          inline
                          value="2"
                          onChange={handleMentalChange}
                          defaultValue="2"
                          checked={mentalStatus === "2"}

                        />
                        <MDBRadio
                          name="mentalStatus"
                          id="flexRadioDefault3"
                          label="3"
                          inline
                          value="3"
                          onChange={handleMentalChange}
                          defaultValue="3"
                          checked={mentalStatus === "3"}

                        />
                        <MDBRadio
                          name="mentalStatus"
                          id="flexRadioDefault4"
                          label="4"
                          inline
                          value="4"
                          onChange={handleMentalChange}
                          defaultValue="4"
                          checked={mentalStatus === "4"}

                        />
                        <MDBRadio
                          name="mentalStatus"
                          id="flexRadioDefault5"
                          label="5"
                          inline
                          value="5"
                          onChange={handleMentalChange}
                          defaultValue="5"
                          checked={mentalStatus === "5"}

                        />
                      </div>
                    </form>

                    <div className="fw-bold text-end text-light">
                      <TextBox label={t("Mental Details")} text={mentalDetails}
                        showTextBox={showMentalTextBox}
                        onInputChange={handleMentalDetailsChange}

                      />
                    </div>
                  </div>


                </MDBContainer>

              </Col>

            </Row>
          </Container>
          <Row className="d-flex justify-content-center">
            <Button className="w-75 m-3 rounded-0 border-0" transparent={false} onClick={handleSubmitAgain}>
              {t("Submit again")}
            </Button>

          </Row>

          {submitButtonClicked && (
            <div className="text-center mt-2 text-danger">
              {t("You need to fill the textbox in order to proceed further")}.
            </div>
          )}

          {!isTextBoxFilled && (
            <div className="text-center mt-2 text-danger">
              {t("You need to fill the textbox before clicking the submit button")}.

            </div>
          )}
          {isSubmitSuccessful && (
            <div className="text-center mt-2 text-success">
              {t("Submission was successful")}.
            </div>
          )}


        </div>


      </div>
    )

  } else {
    return (

      <div>
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ paddingTop: "100px" }}
        >
          <Row>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <h2>{t("Daily survey").toUpperCase()}</h2>
            </Col>

            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <MDBContainer className="d-flex justify-content-center" style={{ width: "100%" }}>
                <div className="mx-0 mx-sm-auto">
                  <form className=" mt-3" action="">
                    <p className="fw-bold text-center">
                      {t("How is your fitness status today")}?
                    </p>

                    <div className="text-center">
                      <MDBRadio
                        name="fitnessStatus"
                        id="flexRadioDefault1"
                        label="1"
                        inline
                        value="1"
                        onChange={handleFitnessChange}
                        defaultValue="1"
                      />
                      <MDBRadio
                        name="fitnessStatus"
                        id="flexRadioDefault2"
                        label="2"
                        inline
                        value="2"
                        onChange={handleFitnessChange}
                        defaultValue="2"
                      />
                      <MDBRadio
                        name="fitnessStatus"
                        id="flexRadioDefault3"
                        label="3"
                        inline
                        value="3"
                        onChange={handleFitnessChange}
                        defaultValue="3"
                      />
                      <MDBRadio
                        name="fitnessStatus"
                        id="flexRadioDefault4"
                        label="4"
                        inline
                        value="4"
                        onChange={handleFitnessChange}
                        defaultValue="4"
                      />
                      <MDBRadio
                        name="fitnessStatus"
                        id="flexRadioDefault5"
                        label="5"
                        inline
                        value="5"
                        onChange={handleFitnessChange}
                        defaultValue="5"
                      />
                    </div>
                  </form>
                  <div className="text-end fw-bold">
                    <TextBox label={t("Fitness Details")} text={fitnessDetails}
                      showTextBox={showFitnessTextBox}
                      onInputChange={handleFitnessDetailsChange}

                    />
                  </div>
                </div>
              </MDBContainer>
            </Col>

            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <MDBContainer className="d-flex justify-content-center" style={{ width: "100%" }}>
                <div className="mx-0 mx-sm-auto">
                  <form className=" mt-3" action="">
                    <p className="fw-bold text-center">
                      {t("How is your mental status today")}?
                    </p>

                    <div className="text-center">
                      <MDBRadio
                        name="mentalStatus"
                        id="flexRadioDefault1"
                        label="1"
                        inline
                        value="1"
                        onChange={handleMentalChange}
                        defaultValue="1"
                      />
                      <MDBRadio
                        name="mentalStatus"
                        id="flexRadioDefault2"
                        label="2"
                        inline
                        value="2"
                        onChange={handleMentalChange}
                        defaultValue="2"
                      />
                      <MDBRadio
                        name="mentalStatus"
                        id="flexRadioDefault3"
                        label="3"
                        inline
                        value="3"
                        onChange={handleMentalChange}
                        defaultValue="3"
                      />
                      <MDBRadio
                        name="mentalStatus"
                        id="flexRadioDefault4"
                        label="4"
                        inline
                        value="4"
                        onChange={handleMentalChange}
                        defaultValue="4"
                      />
                      <MDBRadio
                        name="mentalStatus"
                        id="flexRadioDefault5"
                        label="5"
                        inline
                        value="5"
                        onChange={handleMentalChange}
                        defaultValue="5"
                      />
                    </div>
                  </form>

                  <div className="fw-bold text-end">
                    <TextBox label={t("Mental Details")} text={mentalDetails}
                      showTextBox={showMentalTextBox}
                      onInputChange={handleMentalDetailsChange}

                    />
                  </div>
                </div>


              </MDBContainer>

            </Col>

          </Row>
        </Container>
        <Row className="d-flex justify-content-center pt-4">
          <Col className="d-flex align-items-center justify-content-center"><h3>{t("SleepData")}</h3></Col>
        </Row>
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ paddingTop: "10px" }}
        >
          <Row>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <MDBContainer className="d-flex justify-content-center" style={{ width: "100%" }}>
                <div className="mx-0 mx-sm-auto">
                  <form className=" mt-1" action="">
                    <p className="fw-bold text-center">
                      {t("SleepRate")}?
                    </p>

                    <div className="text-center">
                      <MDBRadio
                        name="sleepStatus"
                        id="flexRadioDefault1"
                        label="1"
                        inline
                        value="1"
                        onChange={handleSleepChange}
                        defaultValue="1"
                      />
                      <MDBRadio
                        name="sleepStatus"
                        id="flexRadioDefault2"
                        label="2"
                        inline
                        value="2"
                        onChange={handleSleepChange}
                        defaultValue="2"
                      />
                      <MDBRadio
                        name="sleepStatus"
                        id="flexRadioDefault3"
                        label="3"
                        inline
                        value="3"
                        onChange={handleSleepChange}
                        defaultValue="3"
                      />
                      <MDBRadio
                        name="sleepStatus"
                        id="flexRadioDefault4"
                        label="4"
                        inline
                        value="4"
                        onChange={handleSleepChange}
                        defaultValue="4"
                      />
                      <MDBRadio
                        name="sleepStatus"
                        id="flexRadioDefault5"
                        label="5"
                        inline
                        value="5"
                        onChange={handleSleepChange}
                        defaultValue="5"
                      />
                    </div>
                  </form>
                  <div className="text-end fw-bold">
                    <TextBox label={t("Fitness Details")} text={fitnessDetails}
                    // showTextBox={showFitnessTextBox}
                    // onInputChange={handleFitnessDetailsChange}

                    />
                  </div>
                </div>
              </MDBContainer>
            </Col>
          </Row>
        </Container>
        <Row className="pt-3"><Col><p className="fw-bold text-center">
          {t("BedTime")}
        </p></Col></Row>
        <Row className="text-center">
      <Col className="text-end mx-2">
        <Form>
          <label>Bed Time:</label>
          <div>
            <select value={bedTimeHour} onChange={(e) => setBedTimeHour(e.target.value)}>
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i} value={String(i).padStart(2, "0")}>
                  {String(i).padStart(2, "0")}
                </option>
              ))}
            </select>
            :
            <select value={bedTimeMinute} onChange={(e) => setBedTimeMinute(e.target.value)}>
              {["00", "10", "20", "30", "40", "50"].map((minute) => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
            </select>
          </div>
        </Form>
      </Col>
      <Col className="text-start mx-2">
        <Form>
          <label>Wake Time:</label>
          <div>
            <select value={wakeTimeHour} onChange={(e) => setWakeTimeHour(e.target.value)}>
              {Array.from({ length: 24 }, (_, i) => (
                <option key={i} value={String(i).padStart(2, "0")}>
                  {String(i).padStart(2, "0")}
                </option>
              ))}
            </select>
            :
            <select value={wakeTimeMinute} onChange={(e) => setWakeTimeMinute(e.target.value)}>
              {["00", "10", "20", "30", "40", "50"].map((minute) => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
            </select>
          </div>
        </Form>
      </Col>

      {/* Ako želite da prikažete bedTime i wakeTime za debugging ili proveru */}
      
    </Row>
    <Row>
    <Col className="text-center mt-3">
        <div>
          <p>Bed Time: {bedTime}</p>
          <p>Wake Time: {wakeTime}</p>
        </div>
      </Col>
    </Row>
        <Row className="d-flex justify-content-center">
          <Button className="w-75 m-3 rounded-0 border-0" transparent={false} onClick={handleSubmit}>
            {t("Submit")}
          </Button>

        </Row>

        {submitButtonClicked && (
          <div className="text-center mt-2 text-danger">
            {t("You need to fill the textbox in order to proceed further")}.
          </div>
        )}

        {!isTextBoxFilled && (
          <div className="text-center mt-2 text-danger">
            {t("You need to fill the textbox before clicking the submit button")}.
          </div>
        )}
        {isSubmitSuccessful && (
          <div className="text-center mt-2 text-success">
            {t("Submission was successful")}.
          </div>
        )}
      </div>

    );
  }


};

export default DailySurveyScreen;
