import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const TextBox = ({ label, text, showTextBox, onInputChange }) => {
  return (
    <Form
      style={{
        display: showTextBox ? 'block' : 'none',
        margin: '1rem 0',
      }}
    >
      <Form.Group controlId="react-bootstrap-text-box">
        {label && <Form.Label style={{ fontWeight: 'bold' }}>{label}</Form.Label>}
        <Form.Control
          as="textarea"
          rows={6} // Set the initial number of rows
          value={text}
          onChange={onInputChange}
          style={{
            width: '100%',
            border: '2px solid #0d6efd',
            backgroundColor:"rgb(17,63,78)",
            color:"white"
          }}
        />
      </Form.Group>
    </Form>
  );
};

TextBox.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showTextBox: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default TextBox;
