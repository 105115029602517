import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";

import ThemeContext from "./theme/ThemeContext";
import theme from "./theme/theme";


import SignInScreen from "./screens/common/login/SignInScreen";
import ResetPasswordScreen from "./screens/common/login/ResetPasswordScreen";
import SignUpScreen from "./screens/common/login/SignUpScreen";
import HomeScreen from './screens/common/HomeScreen'
import AssignedTaskScreen from './screens/common/AssignedTaskScreen'
import NotificationScreen from './screens/common/NotificationScreen'
import CalendarScreen from './screens/common/CalendarScreen'
import DailySurveyScreen from "./screens/player/DailySurveyScreen";


import PlayersScreen from "./screens/staff/PlayersScreen";
import PlayerScreen from "./screens/staff/PlayerScreen";
import TaskListScreen from "./screens/staff/TaskListScreen";
import TasksAssignedScreen from "./screens/staff/TasksAssignedScreen";
import TaskInfoScreen from "./screens/staff/TaskInfoScreen";
import TaskEditScreen from "./screens/staff/TaskEditScreen";
import TasksScheduledScreen from "./screens/staff/TasksScheduledScreen";
import StaffScreen from "./screens/staff/StaffScreen";
import StaffMemberScreen from "./screens/staff/StaffMemberScreen";
import ScheduledTaskInfoScreen from "./screens/staff/ScheduledTaskInfoScreen";
import CreateScheduledTasksScreen from "./screens/staff/CreateScheduledTasksScreen";
import ScheduledTaskCreateScreen from "./screens/staff/ScheduledTaskCreateScreen";

import SysAdminsScreen from "./screens/admins/SysAdminsScreen";
import ClubsScreen from "./screens/admins/ClubsScreen";
import ClubDetailsScreen from "./screens/admins/ClubDetailsScreen";
import CreateClubScreen from "./screens/admins/CreateClubScreen";
import CreateAdminScreen from "./screens/admins/CreateAdminScreen";
import EditAdminScreen from "./screens/admins/EditAdminScreen";
import CreatePlayerScreen from "./screens/staff/CreatePlayerScreen";
import CreateStaffMemberScreen from "./screens/staff/CreateStaffMemberScreen";
import EditStaffScreen from "./screens/staff/EditStaffScreen";
import CreateTaskScreen from "./screens/staff/CreateTaskScreen"
import PlayersGroupsScreen from "./screens/staff/PlayersGroupsScreen";
import PlayersGroupsDetailsScreen from "./screens/staff/PlayersGroupsDetailsScreen";
import EditPlayersGroupsScreen from "./screens/staff/EditPlayersGroupsScreen";


import Header from "./components/common/Header";
import PersonalInfoScreen from "./screens/player/PersonalInfoScreen"
import StaffInfoScreen from "./screens/staff/StaffInfoScreen";
import NewPasswordScreen from "./screens/newPasswordScreen";

import FeatureClubSettings from "./screens/staff/FeatureClubSettings";
import CreatePlayerGroupScreen from "./screens/staff/CreatePlayerGroupScreen";
import EditClubScreen from "./screens/admins/EditClubScreen";
import AdminInfoScreen from "./screens/admins/AdminInfoScreen";
import CalendarStaffScreen from "./screens/staff/CalendarStaffScreen";
const App = () => {
  return (
    <Router>
    
      <Header />
      <main>
        <Container>
          <Routes>
          <Route path="/" element={<HomeScreen />} exact />
            <Route path="/signin" element={<SignInScreen />} />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
            <Route path="/signup" element={<SignUpScreen />} />
            <Route path="/notifications" element={<NotificationScreen />} />
            <Route path="/calendar" element={<CalendarScreen />} />
            <Route path="/daily-survey" element={<DailySurveyScreen />} />
            <Route path="/club/players" element={<PlayersScreen />} />
            <Route path="/club/:clubId/staff" element={<StaffScreen />} />
            <Route path="/club/:clubId/staff/:staffMemberId" element={<StaffMemberScreen />} />
            <Route path="/tasks/list" element={<TaskListScreen />} />
            <Route path="/club/:clubId/task/:taskId" element={<TaskInfoScreen />} />
            <Route path="/club/:clubId/task/:taskId/edit" element={<TaskEditScreen />} />
            <Route path="/tasks/assigned" element={<TasksAssignedScreen />} />
            <Route path="/tasks/scheduled" element={<TasksScheduledScreen  />} />
            <Route path="/tasks/scheduled/create" element={<ScheduledTaskCreateScreen  />} />
            <Route path="/club/:clubId/scheduledtask/:scheduletaskid" element={<ScheduledTaskInfoScreen  />} />
            <Route path="/club/:clubId/scheduledtasks/create" element={<CreateScheduledTasksScreen />} />
            
            <Route path="/admin/admins" element={<SysAdminsScreen />} />
            <Route path="/admin/clubs" element={<ClubsScreen />} />
            <Route path="/admin/clubs/:clubId/details" element={<ClubDetailsScreen />} />
            <Route path="/admin/clubs/create" element={<CreateClubScreen />} />
            <Route path="/calendar/scheduledtasks/:scheduledtaskid" element={<AssignedTaskScreen />} />
            <Route path="/admin/admins/create" element={<CreateAdminScreen/>} />
            <Route path="/admin/admins/:adminId/edit" element={<EditAdminScreen/>} />
            <Route path="/club/players/create" element={<CreatePlayerScreen/>}/>
            <Route path="/player/:playerId" element={<PlayerScreen />} />
            <Route path="/club/staff/create" element={<CreateStaffMemberScreen/>}/>
            <Route path="/club/:clubid/staff/:staffmemberid/edit" element={<EditStaffScreen />}/>
            <Route path="/staff/tasks/create" element={<CreateTaskScreen/>}/>
            <Route path="/personal-info" element={<PersonalInfoScreen/>} />
            <Route path="/staff-personal-info" element={<StaffInfoScreen/>} />
            <Route path="/resetpassword/:resetpasswordtoken" element={<NewPasswordScreen/>} />
            <Route path="/staff-clubsettings" element={<FeatureClubSettings />} />
            <Route path="/staff/clubs/:clubid/playersgroups" element={<PlayersGroupsScreen />} />
            <Route path="/staff/clubs/:clubid/playersgroups/:playersGroupId" element={<PlayersGroupsDetailsScreen />} />
            <Route path="/staff/clubs/:clubid/playersgroups/newgroup" element={<CreatePlayerGroupScreen />} />
            <Route path="/staff/clubs/:clubid/playersgroups/:playersGroupId/edit" element={<EditPlayersGroupsScreen />} />
            <Route path="/admin/clubs/:clubId/editClub" element={<EditClubScreen />} />
            <Route path="/admin/admins/:adminId/info" element={<AdminInfoScreen/>} />
            <Route path="/admin/admins/calendar" element={<CalendarStaffScreen/>} />
          </Routes>
        </Container>
      </main>
      
    </Router>
  );
};

export default App;
