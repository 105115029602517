import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/table/DataTable";
import {
  fetchScheduledTasks,
  createScheduledTask,
  deleteScheduledTask,
  filterScheduledTasks,
} from "../../actions/staff/scheduledActions";
import { fetchTasksList } from "../../actions/staff/tasksListActions";
import { fetchPlayers } from "../../actions/staff/clubPlayerActions";
import ScheduledTaskModal from "../../components/scheduledTaskModal/ModalScheduledTask";
import EditScheduledTaskModal from "../../components/EditScheduledTaskModal";
import FilterModal from "../../components/FilterModal/FilterModal ";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/cofButton/CustomButton";
import { formatDate, formatBirth } from '../../components/dateUtils';
import { faInfo, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import {EDIT_SCHEDULED_TASK_RESET} from "../../constants/staff/scheduledListConstants";
import {FILTER_SCHEDULED_TASKS_RESET} from "../../constants/staff/scheduledListConstants";
import { Container, Alert  } from 'react-bootstrap';
const TasksScheduledScreen = () => {
  const { t } = useTranslation();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  console.log(userInfo);
  const [showFilterForm, setShowFilterForm] = useState(false);
  
  const [showEditModal, setShowEditModal] = useState(false);
  const [editScheduledTaskId, setEditScheduledTaskId] = useState(null);
  const [show, setShow] = useState(false);
  const [filteredScheduledTasks, setFilteredScheduledTasks] = useState([]);
  const [filterData, setFilterData] = useState(null);


  

  const [isFilterActive, setIsFilterActive] = useState(false); // Dodajte stanje za praćenje aktiviranog filtera

  
  

  const [playersData, setPlayersData] = useState([]);

  const editScheduled = useSelector((state) => state.editScheduledTask);
  const { success: successEdit } = editScheduled;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clubId = userInfo.club;

  const deleteScheduledTaskState = useSelector(
    (state) => state.deleteScheduledTask
  );
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = deleteScheduledTaskState;

  const { loading, error, scheduledTasks } = useSelector(
    (state) => state.scheduledTasks
  );
  console.log(scheduledTasks)
  const { tasks } = useSelector((state) => state.tasks);
  console.log(tasks);
  const { filteredTasks } = useSelector((state) => state.filterScheduledTasks);
  console.log(filteredTasks);

  const { players } = useSelector((state) => state.players);

  const { trainers } = useSelector((state) => state.staff);
  console.log(trainers);

  useEffect(() => {
    dispatch(fetchPlayers(userInfo.club));
  }, [dispatch, userInfo.club]);

  console.log(players);
  useEffect(() => {
    if (players) {
      setPlayersData(players);
    }
  }, [players]);

  
  useEffect(() => {
    dispatch(fetchTasksList(clubId));
  }, [dispatch, clubId]);

  useEffect(() => {
    dispatch(fetchScheduledTasks(clubId));
    dispatch({type:EDIT_SCHEDULED_TASK_RESET});
  }, [dispatch, clubId, successDelete, successEdit]);

  const navigate = useNavigate();

  const handleCreateScheduledTasks = () => {
    navigate(`/club/${clubId}/scheduledtasks/create`);
  };
  const handleCreateScheduledTask = () => {
    navigate(`/tasks/scheduled/create`);
  };
  

  const infoActionHandler = (scheduledTaskId) => {
    navigate(`/club/${userInfo.club}/scheduledtask/${scheduledTaskId}`);
  };

  const deleteScheduledTaskHandler = async (scheduledtasksId) => {
    if (
      window.confirm("Da li ste sigurni da želite da obrišete ovaj zadatak?")
    ) {
      dispatch(deleteScheduledTask(userInfo.club, scheduledtasksId));
    }
  };

  const handleShowEditModal = (scheduledTaskId) => {
    setEditScheduledTaskId(scheduledTaskId);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditScheduledTaskId(null);
    setShowEditModal(false);
  };

  const actionHandlers = {
    [t("Info")]: infoActionHandler,
    [t("Delete")]: deleteScheduledTaskHandler,
    [t("Edit")]: handleShowEditModal,
    // ...
  };

  const allowedActions = [
    {
      icon: faInfo,
      style: { backgroundColor: "rgb(0, 198, 19)" },
      label: t("Info"),
    },
    { variant: "info", icon: faEdit, label: t("Edit") },
    { variant: "danger", icon: faTrash, label: t("Delete") },
  ];

  const handleFilter = (filterData) => {
    dispatch(filterScheduledTasks(clubId, filterData));
    setFilterData(filterData);
    setShowFilterForm(false);
    setIsFilterActive(true);
    

    // Ažurirana logika za filtriranje
    if (filterData.taskName) {
      const filteredTasksByName = mappedScheduledTasks.filter((task) =>
        task.imeZadatka
          .toLowerCase()
          .includes(filterData.taskName.toLowerCase())
      );
      setFilteredScheduledTasks(filteredTasksByName);
    } else {
      setFilteredScheduledTasks([]);
    }
  };

  useEffect(() => {
    if (filterData) {
      setFilteredScheduledTasks(scheduledTasks);
    } else {
      setFilteredScheduledTasks([]);
    }
  }, [filterData, scheduledTasks]);

  const mappedScheduledTasks = scheduledTasks
    ? scheduledTasks.map((scheduledTask) => ({
        _id: scheduledTask._id,
        pocetak: scheduledTask.startTime && !isNaN(Date.parse(scheduledTask.startTime))
  ? formatDate(scheduledTask.startTime)
  : null,
        description: scheduledTask.description,
        imeZadatka: scheduledTask.name,
        lokacija: scheduledTask.location,
      }))
    : [];

  const filteredDataForTable = filteredTasks
    ? filteredTasks.map((task) => ({
        _id: task._id,
        pocetak: new Date(task.startTime).toLocaleString(),
        description: task.description,
        lokacija: task.location,
        imeZadatka: task.name,
      }))
    : [];

  console.log(filteredDataForTable);

  useEffect(() => {
    if (filterData) {
      setFilteredScheduledTasks(filteredTasks);
    } else {
      setFilteredScheduledTasks([]);
    }
  }, [filterData, filteredTasks]);

  const buttonStyle = {
    width: "40px", // Postavite željenu širinu dugmadi
    marginRight: "5px",
  };
  if (userInfo.club === null || userInfo.club === undefined) {
    return (
      <Container>
        <Alert variant="danger">
          {t('Trenutno vam nije dodeljen klub')}. {t('Vas ID koji mozete iskoristiti prilikom dodavanja u klub je:')} {userInfo._id}
        </Alert>
      </Container>
    );
  }

  return (
    <div>
        <Row className="my-3 mx-1">
          <Col xs={5} md={5} lg={5} className="text-left">
          <h2>{t("ScheduledTasks").toUpperCase()}</h2>
        </Col >
        <Col xs={2}md={2} lg={2}></Col>
        <Col  xs={5} md={5} lg={5} className="text-end">
          <CustomButton 
            onClick={() => setShowFilterForm(true)}
            className="rounded-0 border-0"
            
          >
            {t("Filter")}
          </CustomButton >
        </Col>
        </Row>
        <Row className="my-3 mx-1">
          <Col xs={5} md={5} lg={5} className="text-left">
          <CustomButton 
            onClick={handleCreateScheduledTasks}
            className="rounded-0 border-0"
            
          >
            {t("CreateScheduledTasks")}
          </CustomButton >
        </Col>
        <Col xs={2}md={2} lg={2}></Col>
        <Col xs={5} md={5} lg={5} className="text-end">
        <CustomButton 
        onClick={handleCreateScheduledTask}
        className="rounded-0 border-0"
        
      >
        {t("CreateScheduledTask")}
      </CustomButton >
        </Col>
        </Row>
        
      
      <FilterModal
        tasks={tasks}
        show={showFilterForm}
        onHide={() => {
          setShowFilterForm(false);
          setFilterData(null); // Reset filterData when closing filter modal
        }}
        onFilter={handleFilter}
        
      />

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <DataTable
          columns={[
            { label: t("StartTime").toUpperCase(), dataKey: "pocetak" },
            { label: t("Description").toUpperCase(), dataKey: "description" },
            { label: t("TaskName").toUpperCase(), dataKey: "imeZadatka" },
            { label: t("Location").toUpperCase(), dataKey: "lokacija" },
          ]}
          data={isFilterActive ? filteredDataForTable : mappedScheduledTasks}
          actions={allowedActions}
          actionLabels={[t("Info"), t("Edit"), t("Delete")]}
          buttonStyle={buttonStyle}
          actionHandlers={actionHandlers}
        />
      )}

      <EditScheduledTaskModal
        show={showEditModal}
        onHide={handleCloseEditModal}
        scheduledtaskId={editScheduledTaskId}
        tasks={tasks}
      />
    </div>
  );
};

export default TasksScheduledScreen;
