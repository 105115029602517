

import "./Sidebar.css";
import React, { useEffect, useState, useRef } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import {  faHome,faCalendar, faCheckSquare, faUsers, faUser, faFutbol, faRunning, faClock, faTasks, faBell, faList, faUserCircle, faCog,  } from "@fortawesome/free-solid-svg-icons";

import "./Sidebar.css";
import { logout } from "../../../actions/userActions";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "./MenuItem";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export default function SideBar() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false); // Dodajte state za praćenje stanja menija
  

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  console.log(userInfo)

  const role = userInfo ? userInfo.roles[0].role : null;

  console.log(userInfo);
  console.log(role);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  

  const handleMenuStateChange = (state) => {
    // Postavite state menija na osnovu stanja menija iz biblioteke react-burger-menu
    setMenuOpen(state.isOpen);
  };

  const logoutHandler = () => {
    // Dispatch-ujemo akciju za izlogovanje
    dispatch(logout());
    // Nakon što se izloguje, redirektujemo korisnika na signin stranicu
    window.location.href = "/signin";
  };
  
  
  const closeMenu = () => {
    // Funkcija koja zatvara meni
    setMenuOpen(false);
  };


 

  const imgplayer = "../../../../public/images/Asset1.png"

  return (
    <Menu isOpen={menuOpen} onStateChange={handleMenuStateChange} className="bm-menu">
    {userInfo && (
      <Container style={{ backgroundColor: "#1d4e5b" , color:"white"}} className="align-items-center">
        <Row className="d-flex align-items-center" >
        <Col>
        {userInfo.profilePicture ? (
          <img
            src={`${userInfo.profilePicture}`}
            alt=""
            style={{ width: '70px', height: '70px', borderRadius: '50%', marginLeft: "30px", marginTop: "10px", marginBottom: "10px" }}
          />
        ) : (
          <MenuItem to="/" icon={faUserCircle} onItemClick={closeMenu} />
        )}
      </Col>
          <Col>
          <div style={{ marginLeft: "-30px", lineHeight:"1.3rem", textAlign:"left" }}>
            <div>{userInfo.name}</div>
            <div>{userInfo.surname}</div>
          </div>
          </Col>
        </Row>
      </Container>
    )}

    {userInfo && role === "PLAYER" && (
      <Container>
        <Row>
          <Col className="pr-3"> {/* Dodajte pr-3 klasu za desni padding */}
            {/* Proslijedite closeMenu funkciju kao onItemClick properti */}
            <MenuItem to="/" icon={faHome} title={t("Home").toUpperCase()} onItemClick={closeMenu} />
          </Col>
          <Col>
          <MenuItem to="/personal-info" icon={faUser} title={t("Personal info").toUpperCase()} onItemClick={closeMenu} />
          </Col>

        </Row>
        <Row>
          <Col>
            {/* Proslijedite closeMenu funkciju kao onItemClick properti */}
            <MenuItem to="/calendar" icon={faCalendar} title={t("Calendar").toUpperCase()} onItemClick={closeMenu} />
          </Col>
          <Col className="pl-3"> {/* Dodajte pl-3 klasu za levi padding */}
            {/* Proslijedite closeMenu funkciju kao onItemClick properti */}
            <MenuItem to="/daily-survey" icon={faCheckSquare} title={t("Daily survey").toUpperCase()} onItemClick={closeMenu} />
          </Col>
        </Row>
      </Container>
    )}
    
    {userInfo && role === "ADMIN" && (
      <Container>
        <Row>
          <Col className="pr-3"> {/* Dodajte pr-3 klasu za desni padding */}
            {/* Proslijedite closeMenu funkciju kao onItemClick properti */}
            <MenuItem to="/" icon={faHome} title={t("Home").toUpperCase()} onItemClick={closeMenu} />
          </Col>
          <Col className="pl-3"> {/* Dodajte pl-3 klasu za levi padding */}
            {/* Proslijedite closeMenu funkciju kao onItemClick properti */}
            <MenuItem to="/admin/clubs" icon={faUsers} title={t("Clubs").toUpperCase()} onItemClick={closeMenu} />
          </Col>
        </Row>
        <Row>
          <Col>
            {/* Proslijedite closeMenu funkciju kao onItemClick properti */}
            <MenuItem to="/admin/admins" icon={faUser} title={t("Admins").toUpperCase()} onItemClick={closeMenu} />
          </Col>
          <Col>
          </Col>
        </Row>
      </Container>
    )}
    
    {userInfo && role === "STAFF" && (
      <Container>
        <Row>
          <Col className="pr-3"> {/* Dodajte pr-3 klasu za desni padding */}
            <MenuItem to="/" icon={faHome} title={t("Home").toUpperCase()} onItemClick={closeMenu} />
          </Col>
          <Col>
          <MenuItem to="/staff-personal-info" icon={faUser} title={t("Personal info").toUpperCase()} onItemClick={closeMenu} />
          </Col>

        </Row>
        <Row>
          <Col>
           
            <MenuItem to="/club/players" icon={faRunning} title={t("Players").toUpperCase()} onItemClick={closeMenu} />
          </Col>
          <Col>
            <MenuItem to="/tasks/list" icon={faList} title={t("TaskList").toUpperCase()} onItemClick={closeMenu} />
          </Col>
        </Row>
        <Row>
          <Col>
            <MenuItem to="/tasks/scheduled" icon={faClock} title={t("ScheduledTasks").toUpperCase()} onItemClick={closeMenu} />
          </Col>
          <Col>
          <MenuItem to="/staff/clubs/:clubid/playersgroups" icon={faUsers} title={t("PlayerGroups").toUpperCase()} onItemClick={closeMenu} />
          </Col>
     
        </Row>
        <Row>
        <Col className="pl-3"> {/* Dodajte pl-3 klasu za levi padding */}
            <MenuItem to={`/club/${userInfo.club}/staff`} icon={faFutbol} title={t("Staff").toUpperCase()} onItemClick={closeMenu} />
          </Col>
                  <Col>
            <MenuItem to="/staff-clubsettings" icon={faCog} title={t("Settings").toUpperCase()} onItemClick={closeMenu} />
          </Col>

        </Row>
        <Row>
        <Col>
        <MenuItem to="/admin/admins/calendar" icon={faCalendar} title={t("Calendar").toUpperCase()} onItemClick={closeMenu} />
        </Col>

        <Col>
        </Col>
        </Row>
        
      </Container>
    )}
    

      <div
      onClick={logoutHandler}
      style={{
        color: 'white',
        position: "absolute",
        left: "50%",
        bottom:"0.1rem",
        transform: "translateX(-50%)",
        cursor: "pointer",
      }}
      >{t("Logout")}</div>
    </Menu>
  );
}
