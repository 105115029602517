import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchTaskDetails } from "../../actions/staff/tasksListActions"; // Zamijenite s odgovarajućom akcijom
import TaskOverview from "../../components/taskoverview/TaskOverview";
import ThemeContext from "../../theme/ThemeContext";
import DataTable from "../../components/table/DataTable";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { faInfo, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate, formatBirth } from '../../components/dateUtils';
import asset from '../../img/asset.png';
const TaskInfoScreen = () => {
  const{t} = useTranslation();
  const { taskId } = useParams();
  console.log(taskId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const taskinfo = useSelector((state) => state.fetchTaskDetails);
  const { loading, error, taskDetails } = taskinfo;
  console.log(taskDetails);
   const mappedScheduledTasks = taskDetails ? taskDetails.scheduledTasks.map((task) => ({
    ...task,
    created: t(task.status)
  })) : [];
console.log(mappedScheduledTasks)
  useEffect(() => {
    dispatch(fetchTaskDetails(userInfo.club, taskId)); // Zamijenite s odgovarajućom akcijom i parametrima
  }, [dispatch, taskId, userInfo.club ]);

  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "50px",
  };
  const containerStyleo = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "50px",
  };
  
  const tableStyleo = {
    borderCollapse: "collapse",
  
   
    fontFamily: fonts.primary,
    color: colors.fontcolor,
    backgroundColor: colors.background,
    backgroundImage: `url(${asset})`, // Postavljanje pozadine
    backgroundSize: "cover", // Slika će pokriti ceo element
    backgroundPosition: "center", // Centriranje slike
    backgroundRepeat: "no-repeat", // Sprečava ponavljanje slike
    backgroundBlendMode: "darken", // Blend mode koji kombinuje sliku sa bojom
  filter: "contrast(1.2) brightness(0.8)", // Povećanje kontrasta na 120%
    margin: "20px 0",
    textAlign: "left",
    minWidth: "380px", // Minimalna širina
    minHeight: "300px", // Minimalna visina
  };
  
  const tdStyle = {
    padding: "10px",
   
    
  };
  const infoActionHandler = (taskId) => {
    navigate(`/club/${userInfo.club}/scheduledtask/${taskId}`);
  };
  const actionHandlers = {
    [t("Info")]: infoActionHandler,
    
  };
 
  return (
    <div>
      <h2 style={containerStyle}>{t("Task Info").toUpperCase()}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        taskDetails && (
          <div style={containerStyle}>
            {/* Prikaz osnovnih informacija o zadatku */}
            <div style={containerStyleo}>
      <table style={tableStyleo}>
        <tbody>
          <tr>
            <td style={tdStyle}>{t("Name")}:</td>
            <td style={tdStyle}>{taskDetails.name}</td>
          </tr>
          <tr>
            <td style={tdStyle}>{t("Type")}:</td>
            <td style={tdStyle}>{t(taskDetails.type)}</td>
          </tr>
          <tr>
            <td style={tdStyle}>{t("Location")}:</td>
            <td style={tdStyle}>{taskDetails.location}</td>
          </tr>
          <tr>
            <td style={tdStyle}>{t("Duration")}</td>
            <td style={tdStyle}>{taskDetails.duration}</td>
          </tr>
          <tr>
            <td style={tdStyle}>{t("Description")}:</td>
            <td style={tdStyle}>{taskDetails.description}</td>
          </tr>
        </tbody>
      </table>
    </div>
            <h2>{t("Scheduled tasks related to this task")}</h2>
           { mappedScheduledTasks ? <DataTable
          columns={[
            { label: t("CreatedAt").toUpperCase(), dataKey: "createdAt", format: formatDate },
            { label: t("StartTime").toUpperCase(), dataKey: "startTime",format: formatDate },
            { label: t("Status").toUpperCase(), dataKey: 'created' }
          ]}
          data={mappedScheduledTasks}
          actions={[
            { icon: faInfo,style: { backgroundColor: 'rgb(0, 198, 19)' }, label: t("Info") },
          ]}
          actionLabels={[t("Info")]}
          buttonStyle={{ marginRight: "5px",width: '40px' }}
          actionHandlers={actionHandlers}
        /> : <h3>'Ovaj task jos uvek nije zakazan'</h3>}
          </div>
        )
      )}
    </div>
  );
};

export default TaskInfoScreen;
