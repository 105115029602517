import axios from "axios";
import {
  USER_NOTIFICATION_REQUEST,
  USER_NOTIFICATION_SUCCESS,
  USER_NOTIFICATION_FAIL
} from "../constants/notificationConstants"

export const getUserNotifications = (userId, page = 1, limit = 10) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_NOTIFICATION_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Dodavanje paginacije u API poziv
    const { data } = await axios.get(
      `/api/users/${userId}/notifications?page=${page}&limit=${limit}`,
      config
    );

    dispatch({
      type: USER_NOTIFICATION_SUCCESS,
      payload: {
        notifications: data.notifications,
        totalNotifications: data.totalNotifications,
        totalPages: data.totalPages,
        currentPage: page,
      },
    });
  } catch (error) {
    dispatch({
      type: USER_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};