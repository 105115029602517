import {
  SCHEDULED_TASKS_REQUEST,
  SCHEDULED_TASKS_SUCCESS,
  SCHEDULED_TASKS_FAIL,
  SCHEDULED_TASKS_RESET,
  FETCH_SCHEDULED_TASK_INFO_REQUEST,
  FETCH_SCHEDULED_TASK_INFO_SUCCESS,
  FETCH_SCHEDULED_TASK_INFO_FAIL,
  CREATE_SCHEDULED_TASKS_FAIL,
  CREATE_SCHEDULED_TASKS_REQUEST,
  CREATE_SCHEDULED_TASKS_RESET,
  CREATE_SCHEDULED_TASKS_SUCCESS,
  EDIT_SCHEDULED_TASK_REQUEST,
  EDIT_SCHEDULED_TASK_SUCCESS,
  EDIT_SCHEDULED_TASK_FAIL,
  EDIT_SCHEDULED_TASK_RESET,
  FETCH_UNASSIGNED_USER_SCHEDULED_TASK_FAIL,
  FETCH_UNASSIGNED_USER_SCHEDULED_TASK_SUCCESS,
  FETCH_UNASSIGNED_USER_SCHEDULED_TASK_REQUEST,
  FETCH_UNASSIGNED_USER_SCHEDULED_TASK_RESET,
  FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_REQUEST,
  FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_SUCCESS,
  FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_FAIL,
  FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_RESET,
  ASSIGN_USER_FAIL,
  ASSIGN_USER_REQUEST,
  ASSIGN_USER_SUCCESS,
  CREATE_BATCH_SCHEDULED_TASKS_REQUEST,
  CREATE_BATCH_SCHEDULED_TASKS_SUCCESS,
  CREATE_BATCH_SCHEDULED_TASKS_FAIL,
  RESET_CREATE_BATCH_SCHEDULED_TASKS_STATE,
  FILTER_SCHEDULED_TASKS_REQUEST,
  FILTER_SCHEDULED_TASKS_SUCCESS,
  FILTER_SCHEDULED_TASKS_FAILURE,
  FILTER_SCHEDULED_TASKS_RESET,

  CONFIRM_ATTENDANCE_REQUEST,
  CONFIRM_ATTENDANCE_SUCCESS,
  CONFIRM_ATTENDANCE_FAIL,
  DECLINE_ATTENDANCE_REQUEST,
  DECLINE_ATTENDANCE_SUCCESS,
  DECLINE_ATTENDANCE_FAIL,
  MARK_AS_RESOLVED_REQUEST,
  MARK_AS_RESOLVED_SUCCESS,
  MARK_AS_RESOLVED_FAIL,
  UNASSIGN_MEMBER_RESET,
  ASSIGN_USER_RESET,

  UNRESOLVE_REQUEST,
  UNRESOLVE_SUCCESS,
  UNRESOLVE_FAIL,
} from "../../constants/staff/scheduledListConstants";

import {
  DELETE_SCHEDULED_TASK_REQUEST,
  DELETE_SCHEDULED_TASK_SUCCESS,
  DELETE_SCHEDULED_TASK_FAIL,
  DELETE_SCHEDULED_TASK_RESET,
} from "../../constants/deleteScheduledTaskConstants";

const scheduledInitialState = null;

export const scheduledTasksReducer = (state = { scheduledTasks: scheduledInitialState }, action) => {
  switch (action.type) {
    case SCHEDULED_TASKS_REQUEST:
      return { loading: true };
    case SCHEDULED_TASKS_SUCCESS:
      return { loading: false, scheduledTasks: action.payload };
    case SCHEDULED_TASKS_FAIL:
      return { loading: false, error: action.payload };
    case SCHEDULED_TASKS_RESET:
      return { scheduledTasks: scheduledInitialState }; // Resetovanje na početno stanje
    default:
      return state;
  }
};
const initialState = {
  loading: false,
  scheduledTask: null,
  error: null,
};

export const ScheduledTaskInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULED_TASK_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SCHEDULED_TASK_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduledTask: action.payload,
      };
    case FETCH_SCHEDULED_TASK_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const unassigninitialState = {
  loading: false,
  error: null,
};

export const unassignMemberReducer = (state = unassigninitialState, action) => {
  switch (action.type) {
    case "UNASSIGN_MEMBER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UNASSIGN_MEMBER_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
      };
    case "UNASSIGN_MEMBER_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case UNASSIGN_MEMBER_RESET:
        return unassigninitialState
    default:
      return state;
  }
};

const createScheduledTaskInitialState = {
  loading: false,
  error: null,
  success: false,
  task: null,
};

export const createScheduledTaskReducer = (
  state = createScheduledTaskInitialState,
  action
) => {
  switch (action.type) {
    case CREATE_SCHEDULED_TASKS_REQUEST:
      return { ...state, loading: true };
    case CREATE_SCHEDULED_TASKS_SUCCESS:
      return { ...state, loading: false, success: true, task: action.payload };
    case CREATE_SCHEDULED_TASKS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_SCHEDULED_TASKS_RESET:
      return createScheduledTaskInitialState;
    default:
      return state;
  }
};

const editScheduledTaskInitialState = {
  loading: false,
  error: null,
  success: false,
  scheduledTask: null,
};

export const editScheduledTaskReducer = (
  state = editScheduledTaskInitialState,
  action
) => {
  switch (action.type) {
    case EDIT_SCHEDULED_TASK_REQUEST:
      return { ...state, loading: true };
    case EDIT_SCHEDULED_TASK_SUCCESS:
      return { ...state, loading: false, success: true, scheduledTask: action.payload };
    case EDIT_SCHEDULED_TASK_FAIL:
      return { ...state, loading: false, error: action.payload };
    case EDIT_SCHEDULED_TASK_RESET:
      return editScheduledTaskInitialState;
    default:
      return state;
  }
};

const deleteScheduledTaskInitialState = {
  deletedScheduledTask: null,
  loading: false,
  error: null,
  success: false,
};

// Reducer
export const deleteScheduledTaskReducer = (state = deleteScheduledTaskInitialState, action) => {
  switch (action.type) {
    case DELETE_SCHEDULED_TASK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case DELETE_SCHEDULED_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        deletedScheduledTask: action.payload,
      };
    case DELETE_SCHEDULED_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case DELETE_SCHEDULED_TASK_RESET:
      return { ...deleteScheduledTaskInitialState, success: false };
    default:
      return state;
  }
};

const unassignedUserInitialState = {
  loading: false,
  error: null,
  unassignedUser: [],
};

export const unassignedUserReducer = (
  state = unassignedUserInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_UNASSIGNED_USER_SCHEDULED_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_UNASSIGNED_USER_SCHEDULED_TASK_SUCCESS:
      return {
        ...state,
        unassignedUser: action.payload,
        loading: false,
        success: true,
      };
    case FETCH_UNASSIGNED_USER_SCHEDULED_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case FETCH_UNASSIGNED_USER_SCHEDULED_TASK_RESET:
        return unassignedUserInitialState;
    default:
      return state;
  }
};

const assigninitialState = {
  loading: false,
  error: null,
  success: false,
};

export const assignUserReducer = (state = assigninitialState, action) => {
  switch (action.type) {
    case ASSIGN_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSIGN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ASSIGN_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case ASSIGN_USER_RESET:
        return assigninitialState;
    default:
      return state;
  }
};

const tasksinitialState = {
  loading: false,
  success: false,
  error: null,
};

export const createBatchScheduledTasksReducer = (state = tasksinitialState, action) => {
  switch (action.type) {
    case CREATE_BATCH_SCHEDULED_TASKS_REQUEST:
      return { ...state, loading: true };
    case CREATE_BATCH_SCHEDULED_TASKS_SUCCESS:
      return { ...state, loading: false, success: true };
    case CREATE_BATCH_SCHEDULED_TASKS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_CREATE_BATCH_SCHEDULED_TASKS_STATE:
      return initialState; // Reset to initial state
    default:
      return state;
  }
};

const filterScheduledTasksInitialState = {
  loading: false,
  filteredTasks: [],
  error: null,
};

export const filterScheduledTasksReducer = (state = filterScheduledTasksInitialState, action) => {
  switch (action.type) {
    case FILTER_SCHEDULED_TASKS_REQUEST:
      return { ...state, loading: true };
    case FILTER_SCHEDULED_TASKS_SUCCESS:
      return { ...state, loading: false, filteredTasks: action.payload };
    case FILTER_SCHEDULED_TASKS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FILTER_SCHEDULED_TASKS_RESET:
      return filterScheduledTasksInitialState; // Resetovanje na početno stanje
    default:
      return state;
  }
};

export const confirmAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_ATTENDANCE_REQUEST:
      return { loading: true };
    case CONFIRM_ATTENDANCE_SUCCESS:
      return { loading: false, success: true };
    case CONFIRM_ATTENDANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const declineAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case DECLINE_ATTENDANCE_REQUEST:
      return { loading: true };
    case DECLINE_ATTENDANCE_SUCCESS:
      return { loading: false, success: true };
    case DECLINE_ATTENDANCE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const markAsResolvedReducer = (state = {}, action) => {
  switch (action.type) {
    case MARK_AS_RESOLVED_REQUEST:
      return { loading: true };
    case MARK_AS_RESOLVED_SUCCESS:
      return { loading: false, success: true };
    case MARK_AS_RESOLVED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const unresolveReducer = (state = {}, action) => {
  switch (action.type) {
    case UNRESOLVE_REQUEST:
      return { loading: true };
    case UNRESOLVE_SUCCESS:
      return { loading: false, success: true };
    case UNRESOLVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


const unassignedStaffInitialState = {
  loading: false,
  error: null,
  unassignedStaff: [],
};

export const fetchUnassignedStaffReducer = (
  state = unassignedStaffInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_SUCCESS:
      return {
        ...state,
        unassignedStaff: action.payload,
        loading: false,
        success: true,
      };
    case FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_RESET:
      return unassignedStaffInitialState;
    default:
      return state;
  }
};
