import { React, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Button from "../cofButton/Button";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { uploadUserPassportImgAction } from "../../actions/uploadAction.js"
import axios from "axios";
import CustomInput from "../../components/customInput/CustomInput"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
const PassportInfo = ({ passportData, updatePassportInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [passportNumber, setPassportNumber] = useState('');
  const [passportExpirationDate, setPassportExpirationDate] = useState('');
  const [nationalityValue, setNationalityValue] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const PassportImg = useSelector((state) => state.uploadUserPassportImg);
  const {sucess: sucesspasimg } = PassportImg;
  console.log(PassportImg)
  
  // Učitaj sliku prilikom pokretanja komponente
  
  console.log(selectedImage);
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Dodavanje vodeće nule
    const day = String(date.getDate()).padStart(2, '0'); // Dodavanje vodeće nule
    return `${year}-${month}-${day}`;
  };
  console.log(passportData);
 

  // Dodati drugi useEffect za praćenje uspeha uplaod-a slike
  
  const handleUpdateInfo = () => {
    const updatedInfo = {
      passportnum: passportNumber,
      passportExpirationDate: passportExpirationDate,
      nationality: nationalityValue,
      passportPicture: selectedImage,
    };
    console.log(updatedInfo)
    updatePassportInfo(updatedInfo);

    setSuccessMessage(t('You have successfully updated your information.'));
    // Uklanjamo poruku nakon 5 sekundi
    setTimeout(() => setSuccessMessage(''), 5000);
  };




  const uploadUserPassportImgHandler = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        dispatch(uploadUserPassportImgAction(file, userInfo._id, (data) => {
          console.log("Uploaded image URL:", data);
          setSelectedImage(data);

        }));

      } catch (error) {
        console.error(error);
      }
    }
  };

  
  useEffect(() => {
    if (passportData) {
      setPassportNumber(passportData.passportnum || '');
      setPassportExpirationDate(formatDate(passportData.passportExpirationDate) || '');
      setNationalityValue(passportData.nationality || '');
      setSelectedImage(passportData.passportPicture || '');
    }
  },[passportData]);


  console.log("Selected Image URL:", selectedImage);

  return (
    <div>
      <h3 className="pt-5"> {t('Passport information')}</h3>
      <div className="text-center py-5">
        
      {selectedImage ? (
          <img
            src={`${selectedImage}`}
            alt="Selected"
            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} size="6x" />
        )}
        <Col xs={10} md={8} className="mx-auto py-3">
          <Form.Control
            id="image-file"  
            type="file"
            label={t("Choose File")}
            custom
            onChange={uploadUserPassportImgHandler}
            style={{ backgroundColor: "#415f74", border: "none", borderRadius: "0", color: "white" }}
          ></Form.Control>
          <h4 style={{ paddingTop: "10px" }}>  {t('numberOfPassport')}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("PassportNum")}
              value={passportNumber}
              onChange={(e) => setPassportNumber(e.target.value)}
            />
          </Form.Group>
          <h4>  {t('Date of validity')}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="date"
              placeholder={t("ExpirationDate")}
              value={passportExpirationDate} // Ispravno formatirana vrednost
              onChange={(e) => setPassportExpirationDate(e.target.value)} // Ažurira stanje
            />
          </Form.Group>
          <h4> {t('Nationality')}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t('Nationality')}
              value={nationalityValue}
              onChange={(e) => setNationalityValue(e.target.value)}
            />
          </Form.Group>
          <Button
            className="w-100 rounded-0 border-0"
            transparent={false}
            onClick={handleUpdateInfo}
          >
            {t("UpdateInfo")}
          </Button>
          {successMessage && (
            <div className="text-success mt-3">
              {successMessage}
            </div>
          )}
        </Col>
        





      </div>
    </div>
  )
}
export default PassportInfo;