import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "./menu/Sidebar.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { fetchContext } from "../../actions/userActions";
import "./Header.css";

export default function Header() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchUserContext = useSelector((state) => state.fetchUserContext);
  const { userInfo: context } = fetchUserContext;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Fetch context kada je korisnik ulogovan
  useEffect(() => {
    if (userInfo) {
      dispatch(fetchContext(userInfo._id));
    }
  }, [dispatch, userInfo]);

  // Prati promene veličine prozora
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const hasNewNotifications = context?.newNotificationsCount > 0;

  return (
    <div>
      <Row className="pt-3 pb-3">
        <Col xs={3} className="d-flex align-items-center justify-content-center">
          <Sidebar />
        </Col>
        <Col xs={6} className="d-flex align-items-center justify-content-center">
          <Link to={`/`}>
            <img
              className="cofimage"
              src="/../../images/LogoCoF.png"
              alt="Concierge of Football logo"
            />
          </Link>
        </Col>
        <Col xs={3} className="d-flex align-items-center justify-content-center">
          <div style={{ position: "relative" }}>
            {/* Uvek prikaži zvono */}
            <Link to={`/notifications`}>
              <FontAwesomeIcon
                icon={faBell}
                className="bellicon"
                style={{
                  color: hasNewNotifications ? "#00c613" : "grey", // Crvena boja ako ima novih notifikacija, zeleno inače
                }}
              />
            </Link>

            {/* Prikaži counter samo ako ima novih notifikacija */}
            {hasNewNotifications && (
              <span
                className="notification-counter"
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-10px",
                  
                  color: "white",
  borderRadius: "50%",
  width: "20px", // Manji krug
  height: "20px", // Manji krug
  lineHeight: "12px", // Centriraj broj unutar kruga
  fontSize: "12px", // Manji font
  fontWeight: "700",
  backgroundColor: "#ff0000", 
  textAlign: "center", // Centriraj tekst
  zIndex: "1",
  padding: "1px", // Bez dodatnog paddinga da bi brojač bio što manji
  display: "flex", // Flexbox za centriranje
  justifyContent: "center", // Centriraj horizontalno
  alignItems: "center", // Centriraj vertikalno
                }}
              >
                {context.newNotificationsCount}
              </span>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
