import { React, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Button from "../../components/cofButton/Button"
import { fetchPlayerInfo, editPlayerInfo } from "../../actions/player/playerActions";
import { useSelector, useDispatch } from "react-redux";
import PassportInfo from "../../components/common/PassportInfo";
import { useNavigate } from "react-router-dom";
import { EDIT_PLAYER_INFO_RESET } from "../../constants/player/playerConstants";
import { editPassportInfo } from "../../actions/passportInfoActions";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import CustomInput from "../../components/customInput/CustomInput";
import { Dropdown } from "react-bootstrap";
import NotificationToggle from "../../components/NotificationToggle";
import { fetchContext } from "../../actions/userActions";
import { uploadPlayerImgAction } from "../../actions/uploadAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { formatDate, formatBirth } from "../../components/dateUtils";


const PersonalInfoScreen = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
    localStorage.setItem('language', selectedOption.value);
  };

  const languageOptions = [
    { value: "en", label: "English", flag: "../../img/united.png" },
    { value: "srb", label: "Serbian", flag: "https://example.com/flags/serbian.png" },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [selectedImage, setSelectedImage] = useState(null);
  const editPassport = useSelector((state) => state.editPassportInfo)
  const { loading: passportLoading, error: passportError, success: passportUpdate } = editPassport;


  const fetchUserContext = useSelector((state) => state.fetchUserContext);
  const { userInfo: context } = fetchUserContext;
  console.log(context);
  console.log(userInfo);
  useEffect(() => {
    console.log("poziva se useEffect");
    dispatch(fetchContext(userInfo._id));
  }, [dispatch, userInfo]);
  const handlePassportUpdate = (updatedInfo) => {
    const passportInfoData = {
      passportnum: updatedInfo.passportnum,
      nationality: updatedInfo.nationality,
      passportExpirationDate: updatedInfo.passportExpirationDate,
      passportPicture: updatedInfo.passportPicture,

    };
    console.log(updatedInfo);
    dispatch(editPassportInfo(userInfo._id, passportInfoData));
  };




  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        dispatch(uploadPlayerImgAction(file, userInfo._id, (data) => {
          setSelectedImage(data);

        }));

      } catch (error) {
        console.error(error);
      }
    }
  };

  const uploadPlayerImg = useSelector((state) => state.uploadPlayerImg);
  const { success: successUploadImg } = uploadPlayerImg;


  const playerInfo = useSelector((state) => state.fetchPlayerInfo)
  const { loading, error, player } = playerInfo;
  console.log(player)
  useEffect(() => {
    console.log('poziva se useEffect')
    console.log(playerInfo)
    if (playerInfo && playerInfo.player && playerInfo.player.playerPassport) {

    } else {
      console.log('poziva se dispatch')
      dispatch(fetchPlayerInfo(userInfo._id))
    }


  }, [dispatch]);
  const [jersyNum, setJersyNum] = useState("");
  const [playerPosition, setPlayerPosition] = useState("");
  const [age, setAge] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); // Za prikaz grešaka
  const [successMessage, setSuccessMessage] = useState(""); // Stanje za poruku o uspehu
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setNewPassword("");
    setConfirmPassword("");
  }, []);

  useEffect(() => {
    console.log(playerInfo);
    console.log('poziva se useeffect u passportInfo');
    if (playerInfo?.player?.playerPersonalInfo) {
      console.log('setuje se state');
      setName(playerInfo.player.user.name);
      setSurname(playerInfo.player.user.surname);
      setEmail(playerInfo.player.user.email);
      setJersyNum(playerInfo.player.playerPersonalInfo.jersynum);
      setPlayerPosition(playerInfo.player.playerPersonalInfo.position);
      setAge(playerInfo.player.playerPersonalInfo.birthdate);
      setSelectedImage(playerInfo.player.playerPersonalInfo.picture);

      // Postavljanje name, surname i email stanja

    }
  }, [playerInfo]);



  const editPlayer = useSelector((state) => state.editPlayerInfo);
  const { loading: editLoading, error: editError, success: successUpdate } = editPlayer;

  const handleUpdateInfo = (e) => {
    e.preventDefault();
    console.log("radi");

    // Provera da li se šifre poklapaju
    if (newPassword !== confirmPassword) {
      setPasswordError(t("Passwords do not match")); // Poruka greške
      return;
    }

    const playerData = {
      jersynum: jersyNum,
      position: playerPosition,
      birthdate: age,
      password: newPassword,
      name: name,
      surname: surname,
      email: email,
    };

    setPasswordError(""); // Reset greške
    console.log(playerData);
    dispatch(editPlayerInfo(userInfo._id, playerData));

    // Prikaz poruke o uspehu
    setSuccessMessage(t("Player information updated successfully."));
    setTimeout(() => setSuccessMessage(""), 5000); // Poruka nestaje nakon 5 sekundi
  };


  useEffect(() => {
    if (successUpdate || successUploadImg) {

      dispatch(fetchPlayerInfo(userInfo._id))
      dispatch({ type: EDIT_PLAYER_INFO_RESET })
    }
  }, [dispatch, navigate, successUpdate, successUploadImg])



  return (
    <div className="text-center py-5">
      <h2>{t("Settings").toUpperCase()}</h2>
      <Row><Col className="text-end" xs={8} lg={6}><h4> {t("ChooseLanguage")}:</h4></Col>
        <Col className="text-start">
          <Dropdown className="dropdown" >
            <Dropdown.Toggle variant="light" id="language-dropdown" style={{ "backgroundColor": "rgba(0,0,0,0)", "color": "#00c613", "border": "none", zIndex: "33" }}>
              {i18n.language === "en" ? "Eng" : "Ser"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ backgroundColor: "#1d4e5b", "border": "none", marginTop: windowWidth <= 760 ? "1rem" : "" }}>
              {languageOptions.map((option) => (
                <Dropdown.Item key={option.value} onClick={() => changeLanguage(option)} style={{ "color": "#00c613" }}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="pb-5">
        <Col className="text-end" xs={8} lg={6}><h4>{t("Notifications")}:</h4></Col>
        <Col className="text-start pt-1">
          <NotificationToggle context={context} />
        </Col>
      </Row>


      <Col xs={8} lg={3} className=" mx-auto py-3">
        {selectedImage ? (
          <img
            src={`${selectedImage}`}
            alt="Selected"
            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
          />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} size="6x" />
        )}
      </Col>
      <h5 style={{ color: "green" }}>id : {userInfo._id}</h5>
      <h6 className="text-center">
        Vaš klub je: {userInfo?.clubName ? userInfo.clubName.toUpperCase() : 'Nije definisano'}
      </h6>

      <Row className="justify-content-center py-3">
        <Col xs={10} md={8} >
          <Form.Control
            id="image-file"
            type="file"
            label="Choose File"
            custom
            onChange={uploadFileHandler}
            style={{ backgroundColor: "#415f74", border: "none", borderRadius: "0", color: "white" }}
          ></Form.Control>
          <h4>{t("Name")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Enter name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <h4>{t("Surname")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Enter surname")}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Form.Group>

          <h4>{t("Email")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="email"
              placeholder={t("Enter email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <h4>  {t('jerseyNumber')}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("jerseyNumber")}
              value={jersyNum}
              onChange={(e) => setJersyNum(e.target.value)} />
          </Form.Group>
          <h4> {t('position')}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Player position")}
              value={playerPosition}
              onChange={(e) => setPlayerPosition(e.target.value)} />
          </Form.Group>
          <h4> {t('age')}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="date" // Zadržava kalendar
              placeholder={t("age")}
              value={age ? new Date(age).toISOString().split("T")[0] : ""} // ISO format za kalendar
              onChange={(e) => {
                const inputDate = e.target.value;
                if (inputDate) {
                  setAge(new Date(inputDate).toISOString()); // Čuvanje u ISO formatu
                }
              }}
            />
            {/* Prikaz datuma u DD-MM-YYYY formatu ispod inputa */}
            {age && (
              <small style={{ display: "block", marginTop: "5px", color: "rgb(0,197,35)" }}>
                {t("Selected date")}: {formatBirth(age)}
              </small>
            )}
          </Form.Group>


          <h4>{t("New Password")}</h4>
          <input type="text" name="hidden-username" style={{ display: "none" }} autoComplete="username" />
          <input type="password" name="hidden-password" style={{ display: "none" }} autoComplete="new-password" />

          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="password"
              placeholder={t("Enter new password")}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Form.Group>

          <h4>{t("Confirm Password")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="password"
              placeholder={t("Confirm new password")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Form.Group>


          {/* Prikaz greške ako šifre ne odgovaraju */}
          {passwordError && (
            <p style={{ color: "red", fontWeight: "bold" }}>{passwordError}</p>
          )}
          <Button
            className="w-100 rounded-0 border-0"
            transparent={false}
            onClick={handleUpdateInfo}
          >
            {t("UpdateInfo")}
          </Button>
          {successMessage && (
            <p style={{ color: "green", fontWeight: "bold", marginTop: "10px" }}>
              {successMessage}
            </p>
          )}
        </Col>

      </Row>
      <PassportInfo
        passportData={playerInfo?.player?.playerPassport}
        updatePassportInfo={handlePassportUpdate} />

    </div>
  )
}

export default PersonalInfoScreen;