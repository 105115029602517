import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserNotifications } from "../../actions/notificationActions";
import { useTranslation } from "react-i18next";
import NotificationMessage from "../../components/notification/NotificationMessage";
import { formatDate } from '../../components/dateUtils';
import Button from "../../components/cofButton/Button";
import ThemeContext from "../../theme/ThemeContext";
import { fetchContext } from "../../actions/userActions";
const NotificationScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchUserContext = useSelector((state) => state.fetchUserContext);
  const { userInfo: context } = fetchUserContext;
  console.log(context);
  const notificationList = useSelector((state) => state.notificationList);
  const {
    notifications,
    totalPages,
    currentPage,
    loading,
    error,
  } = notificationList;

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    // Učitaj prvu stranicu notifikacija
    if (!notifications || notifications.length === 0) {
      dispatch(getUserNotifications(userInfo._id, 1, 10));
    }
  }, [dispatch, userInfo._id]);

  const loadMoreNotifications = () => {
    // Učitavanje sledeće stranice notifikacija
    if (currentPage < totalPages && !isFetching) {
      setIsFetching(true);
      dispatch(getUserNotifications(userInfo._id, currentPage + 1, 10));
    }
  };

  useEffect(() => {
    // Postavi isFetching na false nakon što se notifikacije učitaju
    if (!loading) {
      setIsFetching(false);
    }
  }, [loading]);
  useEffect(() => {
    dispatch(fetchContext(userInfo._id));

  }, [dispatch, userInfo]);

  return (
    <div>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ paddingTop: "100px", flexDirection: "column" }}
      >
        <Row>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <h2>{t("NotificationScreen")}</h2>
          </Col>

          {notifications && notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationMessage
                key={notification._id}
                assigned={
                  notification.assignedBy
                    ? notification.assignedBy.name
                    : t("Assigned by system")
                }
                time={formatDate(notification.createdAt)}
                notification={notification.text}
                toPath={notification.link ? notification.link : null}
              />
            ))
          ) : (
            <p>{t("No Notifications Available")}</p>
          )}

          {loading && (
            <div className="d-flex justify-content-center w-100 my-3">
              <Spinner animation="border" />
            </div>
          )}

          {error && (
            <div className="d-flex justify-content-center w-100 my-3 text-danger">
              {t("Error Loading Notifications")}
            </div>
          )}

          {/* Dugme za učitavanje dodatnih notifikacija */}
          {currentPage < totalPages && !loading && (
            <div className="d-flex justify-content-center w-100 my-3">
              <Button
                onClick={loadMoreNotifications}

                style={{
                  backgroundColor: colors.primaryColor,
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
              >
                {t("Read More")}
              </Button>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default NotificationScreen;
