import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/cofButton/Button";
import { createTask } from '../../actions/staff/tasksListActions'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CustomInput from "../../components/customInput/CustomInput"
import {CREATE_TASK_RESET} from "../../constants/staff/tasksListConstants";

const CreateTaskScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const { loading, error: createTaskError, success } = useSelector((state) => state.createTask);
  const [taskName, setTaskName] = useState("");
  const [taskType, setTaskType] = useState("");
  const [taskLocation, setTaskLocation] = useState("");
  const [taskDuration, setTaskDuration] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [formError, setFormError] = useState(""); // Lokalni state za greške u formi

  const handleTaskCreate = (e) => {
    e.preventDefault();
    
    if (!taskName || !taskType) {
      setFormError(t("Please fill out the task name and select the task type."));
      return;
    }

    setFormError(""); // Resetuj lokalnu grešku ako su svi podaci uneti

    const taskData = {
      name: taskName,
      type: taskType,
      location: taskLocation,
      duration: taskDuration,
      description: taskDescription,
    };

    dispatch(createTask(userInfo.club, taskData));
  };


  useEffect(() => {
    if (success) {
      navigate(`/tasks/list`);
      dispatch({ type: CREATE_TASK_RESET });
    } else if (createTaskError) {
      setFormError(t("An error occurred while creating the task."));
    }
  }, [dispatch, success, createTaskError, navigate, userInfo.club]);

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ paddingTop: "100px" }}
    >
      <Row className="mx-2">
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-center"
        >
          <h2>{t("CreateNewTask").toUpperCase()}</h2>
        </Col>

        <Col xs={12}>
          <Form>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
                type="text"
                placeholder={t("TaskName")}
                value={taskName}
                onChange={(e) => setTaskName(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
              <Form.Control
                as="select"
                value={taskType}
                onChange={(e) => setTaskType(e.target.value)}
                style={{backgroundColor:"#415f74" , borderRadius:"0%", color:"white",opacity:"0.6",border:"none", padding:"0.5rem 0rem", margin:"0.1rem 0rem", textAlign:"center"}}
              >
                <option value="">{t("SelectType")}</option>
                <option value="TRAINING">{t("TRAINING")}</option>
                <option value="MATCHDAY">{t("MATCHDAY")}</option>
                <option value="DINNER">{t("DINNER")}</option>
                <option value="QUARANTINE">{t("QUARANTINE")}</option>
                <option value="TEAMBUILDING">{t("TEAMBUILDING")}</option>
                <option value="FITNESS_TRAINING">{t("FITNESS_TRAINING")}</option>
              </Form.Control>
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
                type="text"
                placeholder={t("TaskLocation")}
                value={taskLocation}
                onChange={(e) => setTaskLocation(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
                type="text"
                placeholder={t("TaskDuration")}
                value={taskDuration}
                onChange={(e) => setTaskDuration(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "10px" }}>
              <CustomInput
                as="textarea"
                rows={4}
                placeholder={t("Task description")}
                value={taskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
              />
            </Form.Group>

            <Button
              className="w-100 rounded-0 border-0"
              transparent={false}
              onClick={handleTaskCreate}
            >
              {t("CreateTask")}
            </Button>
            {formError && (
              <p style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
                {formError}
              </p>
            )}
          </Form>
          
        </Col>
        
      </Row>
      
    </Container>
  );
};

export default CreateTaskScreen;
