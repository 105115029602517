import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchScheduledTaskInfo,
  unassignMember,
  fetchUnassignedUser,
  assignUser,
  fetchUnassignedStaff,
} from "../../actions/staff/scheduledActions";
import DataTable from "../../components/table/DataTable";
import Button from "../../components/cofButton/Button"; // Pretpostavljamo da imate Button komponentu
import ThemeContext from "../../theme/ThemeContext";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ASSIGN_USER_RESET,
  UNASSIGN_MEMBER_RESET,
} from "../../constants/staff/scheduledListConstants";
import Select from "react-select";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { formatDate, formatBirth } from '../../components/dateUtils';
import { createScheduledTaskComment, getScheduledTaskComment } from "../../actions/staff/scheduledTaskCommentsActions";
import FileUploadComponent from "../../components/FileUploadComponent"
import UploadedFilesComponent  from "../../components/UploadedFilesComponent"
import { uploadDocument } from '../../actions/uploadAction';
import { CREATE_SCHEDULED_TASK_COMMENT_RESET} from "../../constants/staff/scheduledTaskCommentsConstants";
import asset from "../../img/asset.png";
const ScheduledTaskInfoScreen = () => {
  const { t } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log(userInfo)
  const { scheduletaskid } = useParams();
  const { clubid } = useParams();
  console.log(scheduletaskid);
  const dispatch = useDispatch();
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const [selectedStaff, setSelectedStaff] = useState(null);
  const [taskComment, setTaskComment] = useState('');
  const handleStaffSelect = (event) => {
    setSelectedStaff(event.target.value);
  };
  const createCommentFunction = useSelector((state) => state.createScheduledTaskComment)
  const { loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = createCommentFunction
  const ScheduledTaskComments = useSelector((state) => state.scheduledTaskComment)
  const { loading: loadingComments,
    error: errorUpdateComments,
    success: successComments,
  } = ScheduledTaskComments


  const handleAddComment = () => {
    // Dispatch the action to create a scheduled task comment
    dispatch(createScheduledTaskComment(scheduletaskid, { body: taskComment, createdBy: userInfo._id }));
    setTaskComment(''); // Clear the comment input after dispatching
  };

 

  useEffect(() => {
    if (successUpdate) {
      dispatch(fetchScheduledTaskInfo(userInfo.club, scheduletaskid));
      dispatch({type:CREATE_SCHEDULED_TASK_COMMENT_RESET})
    }
  }, [dispatch, successUpdate, successComments,scheduletaskid])

  const uploaddoc = useSelector((state) => state.documentUpload);
  const { success: successUpload } = uploaddoc;

  // Koristimo useEffect kuku kako bismo pratili promene u successUpload stanju
  useEffect(() => {
    // Ako je upload uspešan, želimo da ponovo učitamo stranicu
    if (successUpload) {
      // Ovde možete implementirati logiku za ponovno učitavanje stranice
      window.location.reload(); // Ovo je jednostavno ponovno učitavanje stranice
    }
  }, [successUpload]); // useEffect će se ponovo izvršiti kada se promeni successUpload promenljiva

  const handlePlayerSelect = (event) => {
    setSelectedPlayer(event.target.value);
  };

  const assignUserState = useSelector((state) => state.assignUser);
  const {
    loading: loadingAssign,
    error: errorAssign,
    success: successAssign,
  } = assignUserState;
 
  console.log(assignUserState);
  console.log(selectedPlayer);
  const handleAssignPlayerToTask = () => {
    if (selectedPlayer !== null) {
      console.log("Selektovani igrači:", selectedPlayer);
      const selectedPlayerIds = selectedPlayer.map((option) => option.value);
      dispatch(
        assignUser(
          userInfo.club,
          scheduletaskid,
          selectedPlayerIds,
          userInfo._id
        )
      );
      dispatch({ type: ASSIGN_USER_RESET });
    }
  };

  const taskInfo = useSelector((state) => state.scheduledTaskInfo);
  const { loading, error, scheduledTask } = taskInfo;
  console.log(scheduledTask);

  const unassignMemberState = useSelector((state) => state.unassignMember);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = unassignMemberState;

  useEffect(() => {
    dispatch(fetchScheduledTaskInfo(userInfo.club, scheduletaskid));
  }, [dispatch, userInfo.club, scheduletaskid, successDelete, successAssign]);

  const unassignedUsers = useSelector((state) => state.fetchUnassignedUser);
  const { unassignedUser } = unassignedUsers;
  console.log(unassignedUsers)
  console.log(unassignedUser);
  const unassignedStaffs = useSelector((state) => state.fetchUnassignedStaff);
  const { unassignedStaff } = unassignedStaffs;

  console.log(unassignedStaff);

  useEffect(() => {
    console.log(
      "Poziva se useEffect za osvezavanje posle uspesnog asajnovanja"
    );
    console.log(scheduletaskid);
    dispatch(fetchUnassignedUser(userInfo.club, scheduletaskid));
    dispatch(fetchUnassignedStaff(userInfo.club, scheduletaskid));
  }, [successAssign]);

  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;
  console.log(taskInfo);
  const columns = [
    { label: t("AssignedBy").toUpperCase(), dataKey: "assignedBy" },
    { label: t("Assigned To").toUpperCase(), dataKey: "assignedTo" },
    {
      label: t("Confirmation Status").toUpperCase(),
      dataKey: "confirmationStatus",
    },
    {
      label: t("Resolved Status").toUpperCase(),
      dataKey: "resolvedStatus",
    },
  ];
  console.log(scheduledTask);
  const data =
    scheduledTask?.assignments.map((assignment) => ({
      _id: assignment.assignedTo._id,
      userId: assignment.assignedTo._id,
      confirmationStatus: assignment.confirmationStatus
        ? assignment.confirmationStatus.status // Provera da li postoji confirmationStatus pre pristupa statusu
        : "N/A", // Ako ne postoji, prikažite "N/A"
      assignedBy: assignment.assignedBy
        ? assignment.assignedBy.name + " " + assignment.assignedBy.surname
        : "",
      assignedTo:
        assignment.assignedTo.name + " " + assignment.assignedTo.surname,
        resolvedStatus: assignment?.resolvedStatus?.status || "UNRESOLVED",
    })) || [];

  const handleAssignStaffToTask = () => {
    if (selectedStaff !== null) {
      console.log("Selektovano osoblje:", selectedStaff);
      const selectedStaffIds = selectedStaff.map((option) => option.value);
      dispatch(
        assignUser(
          userInfo.club,
          scheduletaskid,
          selectedStaffIds,
          userInfo._id
        )
      );
      dispatch({ type: ASSIGN_USER_RESET });

    }
  };

  const unassignHandler = async (userId) => {
    try {
      dispatch({ type: UNASSIGN_MEMBER_RESET });
      dispatch(unassignMember(userInfo.club, scheduletaskid, userId), {
        type: UNASSIGN_MEMBER_RESET,
      });
      // Nakon unassign operacije, ponovo dohvatite informacije o zakazanom zadatku
      dispatch(fetchScheduledTaskInfo(userInfo.club, scheduletaskid));
    } catch (error) {
      console.error("Error unassigning member:", error);
    }
  };

  const actionHandlers = {
    [t("Unassign")]: unassignHandler,
  };
  console.log(scheduledTask);

  const staffColumns = [
    { label: t("AssignedBy").toUpperCase(), dataKey: "assignedBy" },
    { label: t("Assigned To").toUpperCase(), dataKey: "assignedTo" },
    {
      label: t("Confirmation Status").toUpperCase(),
      dataKey: "confirmationStatus",
    },
    {
      label: t("Resolved Status").toUpperCase(),
      dataKey: "resolvedStatus",
    },
    { label: "Type".toUpperCase(), dataKey: "type" }, // Dodato polje za prikazivanje tipa (osoblje)
  ];

  const staffActions = [
    { variant: "danger", icon: faTrash, label: "Unassign" },
    // Dodajte druge akcije za osoblje prema potrebi
  ];

  const staffActionLabels = ["Unassign"];
  const UnassignStaffHendler = async (userId) => {
    console.log("Poziva se unassign staff, userId: " + userId);
    try {
      dispatch({ type: UNASSIGN_MEMBER_RESET });
      dispatch(unassignMember(userInfo.club, scheduletaskid, userId), {
        type: UNASSIGN_MEMBER_RESET,
      });
      // Nakon unassign operacije, ponovo dohvatite informacije o zakazanom zadatku
      dispatch(fetchScheduledTaskInfo(userInfo.club, scheduletaskid));
    } catch (error) {
      console.error("Error unassigning member:", error);
    }
  };
  const staffActionHandlers = {
    Unassign: UnassignStaffHendler,
  };

  console.log(scheduledTask);

  const staffData =
  scheduledTask?.staffAssignments.map((staffAssignment) => ({
    _id: staffAssignment.assignedTo?._id || "", // Provera da li `assignedTo` postoji
    userId: staffAssignment.assignedTo?._id || "", // Provera da li `assignedTo` postoji
    confirmationStatus: staffAssignment.confirmationStatus
      ? staffAssignment.confirmationStatus.status
      : "N/A",
    assignedBy: staffAssignment.assignedBy
      ? (staffAssignment.assignedBy.name || "") +
        " " +
        (staffAssignment.assignedBy.surname || "")
      : "",
    assignedTo: staffAssignment.assignedTo
      ? (staffAssignment.assignedTo.name || "") +
        " " +
        (staffAssignment.assignedTo.surname || "")
      : "select staff in your settings please", // Koristimo `t` za prevođenje poruke
    type: "Staff",
    resolvedStatus: staffAssignment?.resolvedStatus?.status || "UNRESOLVED",
  })) || [];


    const containerStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      marginTop: "50px",
      
    };
    
    const tableStyle = {
      borderCollapse: "collapse",
      fontFamily: fonts.primary,
      color: colors.fontcolor,
      backgroundColor: colors.background,
      backgroundImage: `url(${asset})`,
      backgroundSize: "cover", // Pokrij ceo element slikom
      backgroundPosition: "center", // Centriraj sliku
      backgroundRepeat: "no-repeat", // Spreči ponavljanje slike
      backgroundBlendMode: "darken", // Blend mode koji kombinuje sliku sa bojom
      filter: "contrast(1.2) brightness(0.8)", // Povećanje kontrasta na 120%
      margin: "20px 0",
      textAlign: "left",
      minWidth: "380px", // Minimalna širina tabele,
      maxWidth:'420px',
      minHeight: "300px", // Minimalna visina tabele
    };

    const tdStyle = {
      padding: "10px",
      
    };

    

  return (
    <div style={{ textAlign: "center" }} className="mx-2">
      <h2>{t("ScheduledTaskInfo").toUpperCase()}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        scheduledTask && (
          <div>
             <div style={containerStyle}>
    <table style={tableStyle}>
      <tbody>
        <tr>
          <td style={tdStyle}>{t("CreatedBy")}:</td>
          <td style={tdStyle}>
  {scheduledTask.createdBy
    ? (scheduledTask.createdBy.name || "") + 
      (scheduledTask.createdBy.surname ? ` ${scheduledTask.createdBy.surname}` : "")
    : "system"}
</td>
        </tr>
        <tr>
        <td style={tdStyle}>{t("StartTime")}:</td>
<td style={tdStyle}>
  {formatDate(scheduledTask.startTime)}
</td>
        </tr>
        <tr>
          <td style={tdStyle}>{t("Name")}:</td>
          <td style={tdStyle}>{scheduledTask.name}</td>
        </tr>
        <tr>
          <td style={tdStyle}>{t("Status")}:</td>
          <td style={tdStyle}>{t(scheduledTask.status)}</td>
        </tr>
        <tr>
          <td style={tdStyle}>{t("Type")}:</td>
          <td style={tdStyle}>{t(scheduledTask.type)}</td>
        </tr>
        <tr>
          <td style={tdStyle}>{t("Location")}:</td>
          <td style={tdStyle}>{scheduledTask.location}</td>
        </tr>
        <tr>
          <td style={tdStyle}>{t("Duration")}:</td>
          <td style={tdStyle}>{scheduledTask.duration}</td>
        </tr>
        <tr>
          <td style={tdStyle}>{t("Description")}:</td>
          <td style={tdStyle}>{scheduledTask.description}</td>
        </tr>
        {scheduledTask.attendanceConfirmationUntil && (
          <tr>
            <td style={tdStyle}>
              {t("Attendance Confirmation Until")}
            </td>
            <td style={tdStyle}>
            {formatDate(scheduledTask.attendanceConfirmationUntil)}
                
             
            </td>
          </tr>
        )}
        {scheduledTask.deadline && (
          <tr>
            <td style={{ padding: "8px" }}>{t("Deadline")}</td>
            <td style={{ padding: "8px" }}>
              {formatDate(scheduledTask.deadline)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
                
              <div style={{ textAlign: "center" }}>
                {/* Ostatak vašeg JSX koda */}
                {/* Dodajte komponentu za upload datoteke */}
                <FileUploadComponent scheduledTaskId={scheduletaskid} />
                <UploadedFilesComponent scheduledTaskId={scheduletaskid} />
              </div>
            <div>




              <div>
                <Row className="py-3">
                  <Col>
                    <h3>{t("Comment")}</h3>
                    <div>
                      {scheduledTask && scheduledTask.comments && scheduledTask.comments.map((comment) => (

                        <div className="border p-2" style={{ width: "350px", textAlign: "left", alignItems: "center", margin: "10px auto" }}>
                          <Row ><Col ><p>{t("CreatedBy")}:&nbsp;&nbsp;{comment.createdBy.name}&nbsp;&nbsp;{comment.createdBy.surname}</p></Col></Row>
                          <Row ><Col><p>{t("CreatedAt")}:&nbsp;&nbsp;{formatDate(comment.createdAt)}</p></Col></Row>
                          <Row ><Col><h6> "{comment.body}"</h6></Col></Row>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div className="py-2">
                  <h5>{t("AddCommentForTask")}</h5>
                  <Row><Col xs={12} lg={10} className="py-1"><Form.Group controlId="taskComment">
                    <Form.Control
                      type="text"
                      value={taskComment}
                      onChange={(e) => setTaskComment(e.target.value)}
                      style={{ backgroundColor: "#415f74", borderColor: "white", borderRadius: "0", color: "white", opacity: "0.6" }}
                    />
                  </Form.Group></Col>
                    <Col xs={12} lg={2} className="py-1">
                      <Button className='rounded-0 border-0' onClick={handleAddComment}>{t("AddComment")}</Button>
                    </Col>
                  </Row>
                </div>
              </div>
              <Row className="my-2">
                <Col xs={6} md={6}>
                  <Select
                    placeholder={t("Select player")}
                    options={unassignedUser.map((user) => ({
                      value: user.user._id,
                      label: `${user.user.name} ${user.user.surname}`,
                    }))}
                    isMulti
                    onChange={(selectedOptions) =>
                      setSelectedPlayer(selectedOptions)
                    }
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        color: "black", // Boja slova opcija
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        color: "black", // Boja slova izabrane vrednosti
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundImage: "linear-gradient(#1d4e5b, #08214c)", // Linearni gradijent pozadine
                        borderRadius: "0", // Uklanjanje zaobljenosti ivice
                        border: "none", // Uklanjanje ivice
                        color: "white", // Bela boja slova
                        cursor: "pointer", // Promena kursora na pokazivač
                      }),
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <Button
                    className="float-end rounded-0 border-0"
                    onClick={handleAssignPlayerToTask}
                    style={{
                      padding:"6px 8px"
                    }}
                  >
                    {t("Assign task to players")}
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="text-center"></div>
            <DataTable
              columns={columns}
              data={data}
              actions={[
                { variant: "danger", icon: faTrash, label: t("Unassign") }, // Samo "Unassign" akcija
              ]}
              actionLabels={[t("Unassign")]} // Samo "Unassign" akcija
              buttonStyle={{ marginRight: "5px" }}
              actionHandlers={actionHandlers}
            />

            <div>
              <Row className="my-2">
                <Col>
                  <Select
                    placeholder={t("Select staff")}
                    options={unassignedStaff.map((staff) => ({
                      value: staff.user._id,
                      label: `${staff.user.name} ${staff.user.surname}`,
                    }))}
                    isMulti
                    onChange={(selectedOptions) =>
                      setSelectedStaff(selectedOptions)
                    }
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        color: "black", // Boja slova opcija
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        color: "black", // Boja slova izabrane vrednosti
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundImage: "linear-gradient(#1d4e5b, #08214c)", // Linearni gradijent pozadine
                        borderRadius: "0", // Uklanjanje zaobljenosti ivice
                        border: "none", // Uklanjanje ivice
                        color: "white", // Bela boja slova
                        cursor: "pointer", // Promena kursora na pokazivač
                      }),
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    className="float-end rounded-0 border-0"
                    onClick={handleAssignStaffToTask}
                  >
                    {t("Assign task to staff")}
                  </Button>
                </Col>
              </Row>
            </div>
            <DataTable
              columns={staffColumns}
              data={staffData}
              actions={staffActions}
              actionLabels={staffActionLabels}
              actionHandlers={staffActionHandlers}
            />
          </div>
        )
      )}
    </div>
  );
};

export default ScheduledTaskInfoScreen;
