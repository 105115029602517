import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Button from "../../components/cofButton/Button";
import { fetchStaffMemberInfo, editStaffMemberInfo } from "../../actions/staff/info/staffInfoActions";
import { fetchContext } from "../../actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import PassportInfo from "../../components/common/PassportInfo";
import { EDIT_STAFF_MEMBER_INFO_RESET } from "../../constants/staff/info/staffInfoConstants";
import { editPassportInfo } from "../../actions/passportInfoActions";
import { useTranslation } from "react-i18next";
import { uploadStaffDocument } from "../../actions/uploadAction";  // Importujte akciju
import CustomInput from "../../components/customInput/CustomInput";
import { Dropdown } from "react-bootstrap";
import { subscribeToNotifications, unsubscribeFromNotifications } from "../../actions/pushNotificationActions";
import NotificationStatusCheck from "../../components/NotificationStatusCheck";
import NotificationToggle from "../../components/NotificationToggle";
import { formatBirth } from "../../components/dateUtils";
import "react-datepicker/dist/react-datepicker.css";
import settings from "../../settings";

const StaffInfoScreen = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (selectedOption) => {
    i18n.changeLanguage(selectedOption.value);
    localStorage.setItem('language', selectedOption.value);  // Sačuvaj izabrani jezik u localStorage
  };

  const languageOptions = [
    { value: "en", label: "English", flag: "../../img/united.png" },
    { value: "srb", label: "Serbian", flag: "https://example.com/flags/serbian.png" },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  const subscribeLoading = useSelector(state => state.notificationSubscribe.loading);
  const unsubscribeLoading = useSelector(state => state.notificationUnsubscribe.loading);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const uploadStaffimg = useSelector((state) => state.uploadStaffDocument);
  const { success: successUpload } = uploadStaffimg;
  console.log(uploadStaffimg);
  const [selectedImage, setSelectedImage] = useState(null);
  const [notification, setNotification] = useState({
    notificationSubscription: null,
  });
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const StaffInfo = useSelector((state) => state.fetchStaffMemberInfo);
  const { loading, error, staffMember } = StaffInfo;
  console.log(StaffInfo);
  const EditStaff = useSelector((state) => state.editStaffMemberInfo);
  const { loading: editLoading, error: editError, success: successUpdate } = EditStaff;
  const editPassport = useSelector((state) => state.editPassportInfo);
  const { loading: passportLoading, error: passportError, success: passportUpdate } = editPassport;

  const fetchUserContext = useSelector((state) => state.fetchUserContext);
  const { userInfo: context } = fetchUserContext;

  const PassportImg = useSelector((state) => state.uploadUserPassportImg);
  const { sucess: sucesspasimg } = PassportImg;



  const handlePassportUpdate = (updatedInfo) => {
    const passportInfoData = {
      passportnum: updatedInfo.passportnum,
      nationality: updatedInfo.nationality,
      passportExpirationDate: updatedInfo.passportExpirationDate,
      passportPicture: updatedInfo.passportPicture,
    };
    console.log(updatedInfo);
    dispatch(editPassportInfo(userInfo._id, passportInfoData));
  };

  useEffect(() => {
    console.log("poziva se useEffect");
    dispatch(fetchContext(userInfo._id));
    dispatch(fetchStaffMemberInfo(userInfo._id));
  }, [dispatch, userInfo]);

  console.log(context);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        dispatch(uploadStaffDocument(file, userInfo._id, (data) => {
          setSelectedImage(data);
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };



  const [education, setEducation] = useState("");
  const [Position, setPosition] = useState("");
  const [age, setAge] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Stanje za poruku o uspehu


  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); // Za prikaz grešaka
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");


  useEffect(() => {
    setNewPassword("");
    setConfirmPassword("");
  }, []);

  useEffect(() => {
    console.log(StaffInfo);
    console.log("poziva se useeffect u passportInfo");
    if (StaffInfo?.trainers?.staffPersonalInfo) {
      console.log("setuje se state ");
      setEducation(StaffInfo.trainers.staffPersonalInfo.education);
      setAge(StaffInfo.trainers.staffPersonalInfo.birthdate);
      setSelectedImage(StaffInfo.trainers.staffPersonalInfo.picture);
      setName(StaffInfo.trainers.user.name); // Preuzimanje imena iz podataka
      setSurname(StaffInfo.trainers.user.surname); // Preuzimanje prezimena iz podataka
      setEmail(StaffInfo.trainers.user.email); // Preuzimanje email-a iz podataka
    }
  }, [StaffInfo]);

  const handleUpdateInfo = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordError(t("Passwords do not match")); // Poruka greške
      return;
    }

    const StaffInfoData = {
      name: name,
      surname,
      email,
      education: education,
      birthdate: age,
      password: newPassword, // Dodavanje šifre u podatke
    };

    setPasswordError(""); // Reset greške
    dispatch(editStaffMemberInfo(userInfo._id, StaffInfoData));

    // Prikaz poruke o uspehu
    setSuccessMessage(t("You have successfully updated your information."));
    setTimeout(() => setSuccessMessage(""), 5000);
  };


  useEffect(() => {
    if (successUpdate || successUpload) {
      dispatch(fetchStaffMemberInfo(userInfo._id));
      dispatch({ type: EDIT_STAFF_MEMBER_INFO_RESET });
    }
  }, [dispatch, successUpdate, successUpload]);

  useEffect(() => {
    // Ovdje možete postaviti početno stanje prekidača ako je potrebno
    // setNotificationEnabled(...)
  }, []);

  const resetNotificationPermissions = () => {
    console.log("Resetovanje dozvola za notifikacije...");
    localStorage.removeItem('notificationPermission');
    console.log("Dozvole za notifikacije su resetovane.");
    // Dodajte dodatnu logiku ako je potrebno za resetovanje dozvola na strani klijenta
  };

  const handleNotificationToggle = () => {
    if (notificationEnabled) {
      unsubscribe();
      resetNotificationPermissions();
    } else {
      subscribe();
    }
  };

  const subscribe = async () => {
    try {
      console.log("subscribe");
      let sw = await navigator.serviceWorker.ready;

      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: settings.webPushNotificationPublicKey
      });

      const formattedPush = push.toJSON();

      const subscriptionData = {
        endpoint: formattedPush.endpoint,
        p256dh: formattedPush.keys.p256dh,
        auth: formattedPush.keys.auth,
      };

      dispatch(subscribeToNotifications(subscriptionData));
      setNotificationEnabled(true);
    } catch (error) {
      console.error("Error subscribing to notifications:", error);
    }
  };

  const unsubscribe = async () => {
    try {
      console.log("unsubscribe");
      dispatch(unsubscribeFromNotifications());
      setNotificationEnabled(false);
    } catch (error) {
      console.error("Error unsubscribing from notifications:", error);
    }
  };





  return (
    <div className="text-center py-5">
      <h2>{t("Settings").toUpperCase()}</h2>
      <Row>
        <Col className="text-end" xs={8} lg={6}>
          <h4> {t("ChooseLanguage")}:</h4>
        </Col>
        <Col className="text-start">
          <Dropdown className="dropdown">
            <Dropdown.Toggle variant="light" id="language-dropdown" style={{ "backgroundColor": "rgba(0,0,0,0)", "color": "#00c613", "border": "none", zIndex: "33" }}>
              {i18n.language === "en" ? "Eng" : "Ser"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ backgroundColor: "#1d4e5b", "border": "none", marginTop: windowWidth <= 760 ? "1rem" : "" }}>
              {languageOptions.map((option) => (
                <Dropdown.Item key={option.value} onClick={() => changeLanguage(option)} style={{ "color": "#00c613" }}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="pb-5">
        <Col className="text-end" xs={8} lg={6}><h4>{t("Notifications")}:</h4></Col>
        <Col>
          <Col className="text-start pt-1">
            <NotificationToggle context={context} />
          </Col>
        </Col>
      </Row>
      {selectedImage && (
        <img
          src={`${selectedImage}`}
          alt="Selected"
          style={{ width: "150px", height: "150px", borderRadius: "50%" }}
        />
      )}
      <Col xs={8} lg={3} className="mx-auto py-3">
        <h5 style={{ color: "green" }}>id : {userInfo._id}</h5>
      </Col>
      <Row className="justify-content-center py-3">
        <Col xs={10} md={8}>
          <Form.Control
            id="image-file"
            type="file"
            label={t("Choose File")}
            custom
            onChange={uploadFileHandler}
            style={{ backgroundColor: "#415f74", border: "none", borderRadius: "0", color: "white" }}
          ></Form.Control>
          <h4>{t("Name")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Enter your name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          {/* Input za prezime */}
          <h4>{t("Surname")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Enter your surname")}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Form.Group>

          {/* Input za email */}
          <h4>{t("Email")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="email"
              placeholder={t("Enter your email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <h4 style={{ paddingTop: "10px" }}> {t("Education")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="text"
              placeholder={t("Education")}
              value={education}
              onChange={(e) => setEducation(e.target.value)}
            />
          </Form.Group>
          <h4> {t("age")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="date" // Zadržava kalendar
              placeholder={t("age")}
              value={age ? new Date(age).toISOString().split("T")[0] : ""} // ISO format za kalendar
              onChange={(e) => {
                const inputDate = e.target.value;
                if (inputDate) {
                  setAge(new Date(inputDate).toISOString()); // Čuvanje u ISO formatu
                }
              }}
            />
            {/* Prikaz datuma u DD-MM-YYYY formatu ispod inputa */}
            {age && (
              <small style={{ display: "block", marginTop: "5px", color: "rgb(0,197,35)" }}>
                {t("Selected date")}: {formatBirth(age)}
              </small>
            )}
          </Form.Group>

          <input type="text" name="hidden-username" style={{ display: "none" }} autoComplete="username" />
          <input type="password" name="hidden-password" style={{ display: "none" }} autoComplete="new-password" />
          <h4>{t("New Password")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="password"
              placeholder={t("Enter new password")}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Form.Group>

          <h4>{t("Confirm Password")}</h4>
          <Form.Group style={{ marginBottom: "10px" }}>
            <CustomInput
              type="password"
              placeholder={t("Confirm new password")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Form.Group>

          {/* Prikaz greške ako šifre ne odgovaraju */}
          {passwordError && (
            <p style={{ color: "red", fontWeight: "bold" }}>{passwordError}</p>
          )}

          <Button className="w-100 rounded-0 border-0" transparent={false} onClick={handleUpdateInfo}>
            {t("UpdateInfo")}
          </Button>
          {successMessage && (
            <p style={{ color: "green", fontWeight: "bold", marginTop: "10px" }}>
              {successMessage}
            </p>
          )}
        </Col>
      </Row>
      <PassportInfo
        passportData={StaffInfo?.trainers?.staffPassport}
        updatePassportInfo={handlePassportUpdate}
      />
    </div>
  );
};

export default StaffInfoScreen;
