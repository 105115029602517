import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../theme/ThemeContext";
import Button from "../../components/cofButton/Button";
import PlayerHomeScreen from "./PlayerHomeScreen";
import StaffHomeScreen from "./StaffHomeScreen";
import AdminHomeScreen from "./AdminHomeScreen";
import { fetchUserAssignedTasks } from "../../actions/AssignedTaskActions";
import { fetchContext } from "../../actions/userActions";
import { useTranslation } from 'react-i18next'; // Import the required actions
import { subscribeToNotifications } from "../../actions/pushNotificationActions";
import settings from "../../settings";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const userAssignedTasks = useSelector((state) => state.userAssignedTasks);

  const { userInfo } = userLogin;
  const { assignedTasks } = userAssignedTasks;
  console.log(userInfo);
  const fetchUserContext = useSelector((state) => state.fetchUserContext);
  const { loading, error, userInfo: context } = fetchUserContext;

  const userRole = userInfo ? userInfo.roles[0].role : null;
  console.log(fetchUserContext);
  console.log(context);


  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }
  }, [navigate, userInfo]);


  useEffect(() => {
    // Check if userInfo is available before dispatching actions
    if (userInfo) {
      subscribe();
      dispatch(fetchContext(userInfo._id));
      dispatch(fetchUserAssignedTasks(userInfo._id));
    }
  }, [dispatch, userInfo]);



  const subscribe = async () => {
    try {
      console.log("subscribe");
      let sw = await navigator.serviceWorker.ready;

      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: settings.webPushNotificationPublicKey
      });

      const formattedPush = push.toJSON();

      const subscriptionData = {
        endpoint: formattedPush.endpoint,
        p256dh: formattedPush.keys.p256dh,
        auth: formattedPush.keys.auth,
      };

      dispatch(subscribeToNotifications(subscriptionData));
    } catch (error) {
      console.error("Error subscribing to notifications:", error);
    }
  };


  const goToDailySurvey = () => {
    navigate("/daily-survey");
  };
  console.log(userInfo);
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div>
          {userRole === "PLAYER" && <PlayerHomeScreen userInfo={userInfo} context={context} />}
          {userRole === "STAFF" && <StaffHomeScreen userInfo={userInfo} context={context} />}
          {userRole === "ADMIN" && <AdminHomeScreen context={context} />}
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default HomeScreen;
