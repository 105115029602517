import axios from "axios";
import {
  SCHEDULED_TASKS_REQUEST,
  SCHEDULED_TASKS_SUCCESS,
  SCHEDULED_TASKS_FAIL,
  CREATE_SCHEDULED_TASKS_FAIL,
  CREATE_SCHEDULED_TASKS_SUCCESS,
  CREATE_SCHEDULED_TASKS_REQUEST,
  FETCH_SCHEDULED_TASK_INFO_REQUEST,
  FETCH_SCHEDULED_TASK_INFO_SUCCESS,
  FETCH_SCHEDULED_TASK_INFO_FAIL,
  UNASSIGN_MEMBER_REQUEST,
  UNASSIGN_MEMBER_SUCCESS,
  UNASSIGN_MEMBER_FAIL,
  EDIT_SCHEDULED_TASK_REQUEST,
  EDIT_SCHEDULED_TASK_SUCCESS,
  EDIT_SCHEDULED_TASK_FAIL,
  FETCH_UNASSIGNED_USER_SCHEDULED_TASK_FAIL,
  FETCH_UNASSIGNED_USER_SCHEDULED_TASK_REQUEST,
  FETCH_UNASSIGNED_USER_SCHEDULED_TASK_SUCCESS,
  ASSIGN_USER_FAIL,
  ASSIGN_USER_REQUEST,
  ASSIGN_USER_SUCCESS,

  FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_REQUEST,
  FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_SUCCESS,
  FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_FAIL,

  CREATE_BATCH_SCHEDULED_TASKS_REQUEST,
  CREATE_BATCH_SCHEDULED_TASKS_SUCCESS,
  CREATE_BATCH_SCHEDULED_TASKS_FAIL,

  FILTER_SCHEDULED_TASKS_REQUEST,
  FILTER_SCHEDULED_TASKS_SUCCESS,
  FILTER_SCHEDULED_TASKS_FAILURE,

  CONFIRM_ATTENDANCE_REQUEST,
  CONFIRM_ATTENDANCE_SUCCESS,
  CONFIRM_ATTENDANCE_FAIL,
  DECLINE_ATTENDANCE_REQUEST,
  DECLINE_ATTENDANCE_SUCCESS,
  DECLINE_ATTENDANCE_FAIL,
  MARK_AS_RESOLVED_REQUEST,
  MARK_AS_RESOLVED_SUCCESS,
  MARK_AS_RESOLVED_FAIL,
  UNRESOLVE_REQUEST,
  UNRESOLVE_SUCCESS,
  UNRESOLVE_FAIL,

} from "../../constants/staff/scheduledListConstants";

import {
  DELETE_SCHEDULED_TASK_REQUEST,
  DELETE_SCHEDULED_TASK_SUCCESS,
  DELETE_SCHEDULED_TASK_FAIL,
} from "../../constants/deleteScheduledTaskConstants";

export const fetchScheduledTasks = (clubId) => async (dispatch, getState) => {
  try {
    dispatch({ type: SCHEDULED_TASKS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/staff/clubs/${clubId}/scheduledtasks`,
      config
    );

    dispatch({
      type: SCHEDULED_TASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SCHEDULED_TASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchScheduledTaskInfo =
  (clubId, scheduledtaskId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_SCHEDULED_TASK_INFO_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/staff/clubs/${clubId}/scheduledtasks/${scheduledtaskId}`,
        config
      );

      dispatch({
        type: FETCH_SCHEDULED_TASK_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_SCHEDULED_TASK_INFO_FAIL,
        payload: error.message,
      });
    }
  };

export const unassignMember =
  (clubId, scheduledTaskId, userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: UNASSIGN_MEMBER_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `/api/staff/clubs/${clubId}/scheduledtasks/${scheduledTaskId}/users/${userId}`,
        config
      );

      dispatch({
        type: UNASSIGN_MEMBER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: UNASSIGN_MEMBER_FAIL,
        payload: message,
      });
    }
  };

export const createScheduledTask =
  (clubId, taskdata) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      dispatch({ type: CREATE_SCHEDULED_TASKS_REQUEST });

      const { data } = await axios.post(
        `/api/staff/clubs/${clubId}/scheduledtasks`,
        taskdata,
        config
      );

      dispatch({
        type: CREATE_SCHEDULED_TASKS_SUCCESS,
        payload: data,
      });

      // After successful creation, fetch updated task list
      dispatch(fetchScheduledTasks(clubId));
    } catch (error) {
      dispatch({
        type: CREATE_SCHEDULED_TASKS_FAIL,
        payload: error.response?.data.message || error.message,
      });
    }
  };

export const editScheduledTask =
  (clubId, scheduledTaskId, taskData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EDIT_SCHEDULED_TASK_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/staff/clubs/${clubId}/scheduledtasks/${scheduledTaskId}`,
        taskData,
        config
      );

      dispatch({
        type: EDIT_SCHEDULED_TASK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_SCHEDULED_TASK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteScheduledTask =
  (clubId, scheduledtasksId) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_SCHEDULED_TASK_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `/api/staff/clubs/${clubId}/scheduledtasks/${scheduledtasksId}`,
        config
      );

      dispatch({
        type: DELETE_SCHEDULED_TASK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_SCHEDULED_TASK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const fetchUnassignedUser =
  (clubId, scheduledtaskId) => async (dispatch, getState) => {
    console.log("Poziva se fetchUnassignedUser akcija, parametri: ");
    console.log(clubId);
    console.log(scheduledtaskId);
    try {
      dispatch({
        type: FETCH_UNASSIGNED_USER_SCHEDULED_TASK_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/staff/clubs/${clubId}/scheduledtasks/${scheduledtaskId}/users/unassigned`,
        config
      );

      dispatch({
        type: FETCH_UNASSIGNED_USER_SCHEDULED_TASK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_UNASSIGNED_USER_SCHEDULED_TASK_FAIL,
        payload: error.message,
      });
    }
  };

  export const assignUser =
  (clubId, scheduledtaskId, userIds, assignedById) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      dispatch({ type: ASSIGN_USER_REQUEST });

      const { data } = await axios.post(
        `/api/staff/clubs/${clubId}/scheduledtasks/${scheduledtaskId}/users`, { assignedById, userIds } ,config
      );

      dispatch({
        type: ASSIGN_USER_SUCCESS,
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: ASSIGN_USER_FAIL,
        payload: error.response?.data.message || error.message,
      });
    }
  };


 


export const createBatchScheduledTasks = (clubId, batchData) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    dispatch({ type: CREATE_BATCH_SCHEDULED_TASKS_REQUEST });

    const { data } = await axios.post(
      `/api/staff/clubs/${clubId}/scheduledtasks/batch`,
      batchData,
      config
    );

    dispatch({
      type: CREATE_BATCH_SCHEDULED_TASKS_SUCCESS,
      payload: data,
    });

    
  } catch (error) {
    dispatch({
      type: CREATE_BATCH_SCHEDULED_TASKS_FAIL,
      payload: error.response?.data.message || error.message,
    });
  }
};

export const filterScheduledTasks = (clubId, filterData) => async (dispatch, getState) => {
  console.log(filterData, "ovo je datafilter");
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    dispatch({ type: FILTER_SCHEDULED_TASKS_REQUEST });
    console.log(filterData);

    const { data } = await axios.post(
      `/api/staff/clubs/${clubId}/scheduledtasks/filter`,
      filterData,
      config
    );
      console.log(data);
    dispatch({
      type: FILTER_SCHEDULED_TASKS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FILTER_SCHEDULED_TASKS_FAILURE,
      payload: error.response?.data.message || error.message,
    });
  }
};

export const confirmAttendance = (userId, scheduledTaskId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONFIRM_ATTENDANCE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/users/${userId}/scheduledtasks/${scheduledTaskId}/confirmattendance`, {}, config);

    dispatch({ type: CONFIRM_ATTENDANCE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CONFIRM_ATTENDANCE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

// Funkcija za odbijanje prisustva
export const declineAttendance = (userId, scheduledTaskId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DECLINE_ATTENDANCE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/users/${userId}/scheduledtasks/${scheduledTaskId}/declineattendance`, {}, config);

    dispatch({ type: DECLINE_ATTENDANCE_SUCCESS });
  } catch (error) {
    dispatch({
      type: DECLINE_ATTENDANCE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};


export const markAsResolved = (userId, scheduledTaskId) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARK_AS_RESOLVED_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/users/${userId}/scheduledtasks/${scheduledTaskId}/resolve`, {}, config);

    dispatch({ type: MARK_AS_RESOLVED_SUCCESS });
  } catch (error) {
    dispatch({
      type: MARK_AS_RESOLVED_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};


export const unresolve = (userId, scheduledTaskId) => async (dispatch, getState) => {
  try {
    dispatch({ type: UNRESOLVE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/users/${userId}/scheduledtasks/${scheduledTaskId}/unresolve`, {}, config);

    dispatch({ type: UNRESOLVE_SUCCESS });
  } catch (error) {
    dispatch({
      type: UNRESOLVE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};




export const fetchUnassignedStaff = (clubId, scheduledtaskId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/staff/clubs/${clubId}/scheduledtasks/${scheduledtaskId}/users/unassignedstaff`,
      config
    );

    dispatch({
      type: FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_UNASSIGNED_STAFF_SCHEDULED_TASK_FAIL,
      payload: error.message,
    });
  }
};