import {
    USER_NOTIFICATION_REQUEST,
    USER_NOTIFICATION_SUCCESS ,
    USER_NOTIFICATION_FAIL,
} from "../constants/notificationConstants"

const initialState = {
  notifications: [], // Početno prazan niz za notifikacije
  totalNotifications: 0, // Ukupan broj notifikacija
  totalPages: 0, // Ukupan broj stranica
  currentPage: 0, // Trenutna stranica
  loading: false,
  error: null,
};

export const getNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_NOTIFICATION_REQUEST:
      return { ...state, loading: true };

    case USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: [...state.notifications, ...action.payload.notifications], // Spajanje novih sa postojećima
        totalNotifications: action.payload.totalNotifications, // Ukupan broj notifikacija
        totalPages: action.payload.totalPages, // Ukupan broj stranica
        currentPage: action.payload.currentPage, // Trenutna stranica
      };

    case USER_NOTIFICATION_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
