import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';
import {
  subscribeToNotifications,
  unsubscribeFromNotifications,
} from '../actions/pushNotificationActions';
import settings from '../settings';

const NotificationToggle = ({ context }) => {
  const dispatch = useDispatch();
  const subscribeLoading = useSelector((state) => state.notificationSubscribe);
  const { loading, error, success: SuccessSubscribe } = subscribeLoading;
  const unsubscribeLoading = useSelector(
    (state) => state.notificationUnsubscribe.loading
  );
  const [notificationEnabled, setNotificationEnabled] = useState(false);
  const [permissionError, setPermissionError] = useState('');

  useEffect(() => {
    if (context && context.isSubscribed) {
      setNotificationEnabled(true);
    }
  }, [context]);

  const handleNotificationToggle = async () => {
    const permission = Notification.permission;

    if (permission !== 'granted') {
      // Provera dozvola za notifikacije
      if (permission === 'denied') {
        setPermissionError('Dozvole za notifikacije su odbijene. Omogućite ih u podešavanjima pretraživača.');
      } else if (permission === 'default') {
        const result = await Notification.requestPermission();
        if (result !== 'granted') {
          setPermissionError('Morate omogućiti dozvole za notifikacije da biste ih koristili.');
          return;
        }
      } else {
        setPermissionError('Nepoznat status dozvola za notifikacije.');
        return;
      }
    }

    // Ako su dozvole u redu, uključujemo ili isključujemo notifikacije
    if (notificationEnabled) {
      unsubscribe();
    } else {
      subscribe();
    }
  };

  const subscribe = async () => {
    try {
      let sw = await navigator.serviceWorker.ready;

      let push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: settings.webPushNotificationPublicKey
      });

      const formattedPush = push.toJSON();

      const subscriptionData = {
        endpoint: formattedPush.endpoint,
        p256dh: formattedPush.keys.p256dh,
        auth: formattedPush.keys.auth,
      };

      dispatch(subscribeToNotifications(subscriptionData));
      setNotificationEnabled(true);
      setPermissionError(''); // Resetovanje greške
    } catch (error) {
      setPermissionError('Greška prilikom pretplate na notifikacije.');
      console.error('Error subscribing to notifications:', error);
    }
  };

  const unsubscribe = async () => {
    try {
      dispatch(unsubscribeFromNotifications());
      setNotificationEnabled(false);
    } catch (error) {
      setPermissionError('Greška prilikom odjave od notifikacija.');
      console.error('Error unsubscribing from notifications:', error);
    }
  };

  return (
    <div>
      <Switch
        checked={notificationEnabled}
        onChange={handleNotificationToggle}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        className="react-switch"
      />
      {permissionError && (
        <p style={{ color: 'red', marginTop: '10px' }}>{permissionError}</p>
      )}
    </div>
  );
};

export default NotificationToggle;
