import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchScheduledTaskInfo } from "../../actions/staff/scheduledActions";
import ThemeContext from "../../theme/ThemeContext";
import { markAsResolved, unresolve, confirmAttendance, declineAttendance } from "../../actions/staff/scheduledActions";
import CustomButton from "../../components/cofButton/CustomButton";
import Button from "../../components/cofButton/Button";
import { useTranslation } from 'react-i18next';
import { formatDate, formatBirth } from '../../components/dateUtils';
import asset from '../../img/asset.png';
import { Row, Col, Form } from "react-bootstrap";
const AssignedTaskScreen = () => {
  const { scheduledtaskid } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const taskInfo = useSelector((state) => state.scheduledTaskInfo);
  const { loading, error, scheduledTask } = taskInfo;
  console.log(scheduledTask);
  console.log(userInfo)
  const attendanceState = useSelector((state) => state.confirmAttendance);
  const { loading: loadingAttedance, error: errorconfirmAtt, success: confirmsuccess } = attendanceState;
  const declineState = useSelector((state) => state.declineAttendance);
  const { loading: loadingdecline, error: errordecline, success: declinesuccess } = declineState;
  const resolved = useSelector((state) => state.markAsResolved);
  const { loading: loadingresolved, error: errorResolved, success: resolvedsuccess } = resolved;
  const unresolved = useSelector((state) => state.unresolved);
  const { loading: loadingunresolved, error: errorunResolved, success: unresolvedsuccess } = unresolved;

  const [resolvedNotification, setResolvedNotification] = useState(
    localStorage.getItem("resolvedNotification") || null
  );

  // Dohvatite vrednosti boja i fontova iz ThemeContext-a
  const theme = useContext(ThemeContext);
  const { colors, fonts } = theme;
  const [notification, setNotification] = useState(null);

  const [isConfirmed, setIsConfirmed] = useState(false); // Stanje za blokiranje dugmeta
  const [buttonColor, setButtonColor] = useState(colors.primaryColor); // Stanje za boju dugmeta

  useEffect(() => {
    if (userInfo) {
      dispatch(fetchScheduledTaskInfo(userInfo.club, scheduledtaskid));
    }
  }, [dispatch, userInfo.club, scheduledtaskid]);

  useEffect(() => {
    // Kombinovanje assignments i staffAssignments
    const allAssignments = [
      ...(scheduledTask?.assignments || []),
      ...(scheduledTask?.staffAssignments || []),
    ];

    // Proveravanje da li postoji odgovarajući zadatak
    const foundAssignment = allAssignments.find(
      (assignment) =>
        assignment.assignedTo?._id === userInfo._id &&
        assignment.confirmationStatus?.status === "CONFIRMED"
    );

    // Postavljanje stanja dugmeta
    if (foundAssignment) {
      setIsConfirmed(true); // Dugme je potvrđeno
      setButtonColor("gray"); // Postavi dugme u sivoj boji
    } else {
      setIsConfirmed(false); // Dugme nije potvrđeno
      setButtonColor(colors.primaryColor); // Default boja dugmeta
    }
  }, [scheduledTask, userInfo._id]);

  useEffect(() => {
    
    if (declinesuccess || resolvedsuccess || unresolvedsuccess) {
      // Ponovno učitajte podatke o zadatku (osvežite stranicu)
      dispatch(fetchScheduledTaskInfo(userInfo.club, scheduledtaskid));
     
    }
  }, [declinesuccess, resolvedsuccess, unresolvedsuccess, dispatch]);

  useEffect(() => {
    if (confirmsuccess) {
      setIsConfirmed(true); // Postavi dugme kao "Confirmed"
      setButtonColor("gray"); // Menjaj boju dugmeta na sivo
    }
  }, [confirmsuccess]); // Kada se potvrda završi, pokreni ovaj effect

  // Funkcija za pronalaženje zadatka na osnovu korisnikove uloge
  const findTaskForUserRole = () => {
    if (userInfo.roles && userInfo.roles.length > 0) {
      const role = userInfo.roles[0].role;

      if (role === "PLAYER") {
        return scheduledTask?.assignments.find((assignment) => assignment.assignedTo._id === userInfo._id);
      } else if (role === "STAFF") {
        return scheduledTask?.staffAssignments.find((staffAssignment) => staffAssignment.assignedTo._id === userInfo._id);
      }
    }

    return null;
  };

  const foundTask = findTaskForUserRole();

  // Dohvat assignedBy i assignedTo u zavisnosti od korisnikove uloge
  const assignedBy = foundTask?.assignedBy || (foundTask?.staffAssignments[0]?.assignedBy || {});
  const assignedTo = foundTask?.assignedTo || (foundTask?.staffAssignments[0]?.assignedTo || {});

  const showNotification = (message, type) => {
    setNotification(message, type);
    // Ne koristimo setTimeout kako bismo obaveštenje ostavili vidljivim
  };
  useEffect(() => {
    const savedNotification = localStorage.getItem("resolvedNotification");
    if (savedNotification) {
      setResolvedNotification(savedNotification);
    }
  }, []);

  const handleMarkAsResolved = () => {
    dispatch(markAsResolved(userInfo._id, scheduledTask._id)); // Dispečujte akciju
    const notification = t("You have confirmed task completion.");
    setResolvedNotification(notification);
    localStorage.setItem("resolvedNotification", notification); // Čuvamo obavještenje u localStorage
    showNotification("Task Marked as Resolved.", "RESOLVED");
  };

  const handleUnresolve = () => {
    dispatch(unresolve(userInfo._id, scheduledTask._id));
    setResolvedNotification(null);
    localStorage.removeItem("resolvedNotification"); // Uklanjamo obaveštenje iz localStorage-a
    showNotification("Task marked as unresolved.", "UNRESOLVED");
  };

  const handleConfirmAttendance = () => {
    dispatch(confirmAttendance(userInfo._id, scheduledTask._id));
    setIsConfirmed(true); // Blokira dugme
    setButtonColor("gray"); // Menja boju dugmeta na sivo (ili bilo koju željenu boju)
    showNotification(t("You have confirmed attendance"), "confirmed");// Prikazuje obaveštenje
  };

  const handleDeclineAttendance = () => {
    dispatch(declineAttendance(userInfo._id, scheduledTask._id));
    showNotification("Odbili ste prisustvo.");
  };


  


 
  const isResolved = () => {
    const allAssignments = [
      ...(scheduledTask?.assignments || []),
      ...(scheduledTask?.staffAssignments || []),
    ];
  
    // Pronađite zadatak koji je dodeljen korisniku
    const assignedTask = allAssignments.find(
      (assignment) => assignment.assignedTo?._id === userInfo?._id
    );
    console.log(assignedTask);
    // Proverite da li postoji resolvedStatus i da li je status "RESOLVED"
    return assignedTask?.resolvedStatus?.status === "RESOLVED" || false;
  };

  const tableStyle = {
    borderCollapse: "collapse",
    fontFamily: fonts.primary,
    color: colors.fontcolor,
    backgroundColor: colors.background,
    backgroundImage: `url(${asset})`, // Postavljanje pozadine
    backgroundBlendMode: "darken", // Blend mode koji kombinuje sliku sa bojom
    filter: "contrast(1.2) brightness(0.8)", // Povećanje kontrasta na 120%
    backgroundSize: "cover", // Slika će pokriti ceo element
    backgroundPosition: "center", // Centriranje slike
    backgroundRepeat: "no-repeat", // Sprečava ponavljanje slike
    margin: "20px 0",
    textAlign: "left",

    minHeight: "300px", // Minimalna visina


  };
  const tdStyle = {
    padding: "10px",
  };

  
  

  console.log(scheduledTask);
  return (
    <div style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
      {/* Renderujte podatke za jedinstveni zadatak */}
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <div className="pt-5">
          {/* Dugmad za potvrdu i odbijanje prisustva */}
          {scheduledTask?.attendanceConfirmationUntil && (

            <div style={{ marginTop: "20px" }} className="mx-2">
              <p>
                {t("Attendance Confirmation Until")}:{" "}
                {formatDate(scheduledTask?.attendanceConfirmationUntil)}
              </p>
              <Row className="d-flex justify-content-between align-items-center">
                <Col className="d-flex justify-content-start">
                  <Button
                    style={{
                      backgroundColor: isConfirmed ? "gray" : buttonColor, // Menja boju dugmeta
                      color: colors.buttonTextColor,
                      padding: "4px 10px",
                      border: "none",
                      cursor: isConfirmed ? "not-allowed" : "pointer", // Onemogućava klik ako je prisustvo potvrđeno
                      marginRight: "40px",
                      minWidth: "150px"
                    }}
                    className="rounded-0 border-0"
                    onClick={handleConfirmAttendance}
                    disabled={isConfirmed || confirmsuccess} // Blokira dugme ako je prisustvo potvrđeno
                  >
                    {isConfirmed ? (
                      <>
                        {t("Attendance")}<br />{t("Confirmed")}
                      </>
                    ) : (
                      <>
                        {t("Confirm")}<br />{t("Attendance")}
                      </>
                    )}
                  </Button>
                  {notification && (
                    <div style={{ marginTop: "10px", color: notification.type === "confirmed" ? "blue" : "green" }}>
                      {notification.message}
                    </div>

                  )}
                </Col>
                <Col className="d-flex justify-content-start">
                  <Button
                    style={{
                      backgroundColor: colors.secondaryColor,
                      color: colors.buttonTextColor,
                      padding: "4px 10px",
                      border: "none",
                      cursor: "pointer",
                      minWidth: "150px"
                    }}
                    className="rounded-0 border-0 my-2"
                    onClick={handleDeclineAttendance}
                  >
                    {t('Decline')}<br /> {t('Attendance')}

                  </Button>

                </Col>
              </Row>
              {notification && <div style={{ marginTop: "10px", color: "green" }}>{notification}</div>}
            </div>
          )}
          {/* Dugmad za resolve/unresolve */}
          {/* Dugmad za resolve/unresolve */}
          <p>
                {t("Deadline")}:{" "}
                {formatDate(scheduledTask?.deadline)}
              </p>
        {isResolved() ? (
          <div style={{ marginTop: "20px" }} className="mx-2">
            
            <Button
              style={{
                backgroundColor: colors.secondaryColor,
                color: colors.buttonTextColor,
                padding: "4px 10px",
                border: "none",
                cursor: "pointer",
                minWidth: "150px",
              }}
              onClick={handleUnresolve}
              className="rounded-0 border-0"
            >
              {t("Mark as")}
              <br /> {t("Unresolved")}
            </Button>
          </div>
        ) : (
          <div style={{ marginTop: "20px" }} className="mx-2">
            <Button
              style={{
                backgroundColor: colors.primaryColor,
                color: colors.buttonTextColor,
                padding: "4px 10px",
                border: "none",
                cursor: "pointer",
                minWidth: "150px",
              }}
              onClick={handleMarkAsResolved}
              className="rounded-0 border-0"
            >
              {t("Mark as")}
              <br /> {t("Resolved")}
            </Button>
          </div>
        )}
        {/* Prikazivanje obaveštenja */}
        {notification && (
          <div
            style={{
              marginTop: "10px",
              color:
                notification.type === "RESOLVED"
                  ? "green"
                  : notification.type === "UNRESOLVED"
                  ? "orange"
                  : "red",
            }}
          >
            {notification.message}
            {resolvedNotification}
          </div>
          
        )}

          <table style={tableStyle}>
            <thead>

            </thead>
            <tbody>
            
              <tr>
                <td style={tdStyle}>{t("AssignedBy")}</td>
                <td style={tdStyle}>{assignedBy?.name} {assignedBy?.surname}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("Assigned To")}</td>
                <td style={tdStyle}>{assignedTo?.name} {assignedTo?.surname}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("TaskName")}</td>
                <td style={tdStyle}>{scheduledTask?.name}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("CreatedAt")}</td>
                <td style={tdStyle}>{formatDate(scheduledTask?.createdAt)}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("Updated At")}</td>
                <td style={tdStyle}>{formatDate(scheduledTask?.updatedAt)}</td>
              </tr>
              
              <tr>
                <td style={tdStyle}>{t("TaskType")}</td>
                <td style={tdStyle}>{scheduledTask?.type}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("TaskLocation")}</td>
                <td style={tdStyle}>{scheduledTask?.task?.location}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("Task description")}</td>
                <td style={tdStyle}>{scheduledTask?.description}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("Task Start Time")}</td>
                <td style={tdStyle}>{formatDate(scheduledTask?.startTime)}</td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("Attendance Confirmation Until")}</td>
                <td style={tdStyle}>
                  {scheduledTask?.attendanceConfirmationUntil ? formatDate(scheduledTask.attendanceConfirmationUntil) : "N/A"}
                </td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("Deadline")}</td>
                <td style={tdStyle}>
                  {scheduledTask?.deadline ? formatDate(scheduledTask.deadline) : "N/A"}
                </td>
              </tr>
              <tr>
                <td style={tdStyle}>{t("TaskID")}</td>
                <td style={tdStyle}>{scheduledTask?._id}</td>
              </tr>
            </tbody>
          </table>

        </div>
      )}
    </div>
  );
};

export default AssignedTaskScreen;
