import axios from "axios";
import { 
    FETCH_PLAYER_INFO_FAIL,
    FETCH_PLAYER_INFO_REQUEST,
    FETCH_PLAYER_INFO_SUCCESS,
    EDIT_PLAYER_INFO_FAIL,
    EDIT_PLAYER_INFO_REQUEST,
    EDIT_PLAYER_INFO_SUCCESS,
    EDIT_PLAYER_INFO_RESET


} from "../../constants/player/playerConstants";

export const editPlayerInfo = (userid, playerInfoData) => async (dispatch, getState) =>{
    try {
     dispatch({
         type: EDIT_PLAYER_INFO_REQUEST,
     });
     const {
       userLogin: { userInfo },
     } = getState();
 
     const config = {
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${userInfo.token}`,
       },
     };
   
       const { data } = await axios.put(
       `/api/users/${userid}/personalinfo`,
       playerInfoData,
         config
       );
      console.log(data)
       dispatch({
         type: EDIT_PLAYER_INFO_SUCCESS,
         payload: data,
       });
 
    } catch (error) {
     dispatch({
       type: EDIT_PLAYER_INFO_FAIL,
       payload:
         error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
     });
   }
 
 }

 export const fetchPlayerInfo = (userid) => async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_PLAYER_INFO_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`/api/users/${userid}/personalinfo`, config);
      console.log("API Response:", data);
      dispatch({ type: FETCH_PLAYER_INFO_SUCCESS , payload: data });
    } catch (error) {
      dispatch({ type: FETCH_PLAYER_INFO_FAIL, payload: error.message });
    }
  };